import React, { Component } from 'react';
import {
  ColorButton,
  HeatingCostAllocationRow,
  SwitchContainer,
  HeatingCostModal,
  HeadingContainer,
  Divider,
  MobileHeatingCostAllocation,
  EmptyStateContainer,
  ShowModal, 
  ImageModal
} from '../../../../index';
import { connect } from 'react-redux';
import {
  setCostPositions,
  addCostPosition,
  setHeatingAllocation,
  setUtilityBillingData,
} from '../../../../../redux/action/UtilityBilling/UtilityBillingActions';
import {
  setRunUpdateReceiptAssignment,
  setRunUpdateTenantOccupations,
  setRunUpdateConsumptions,
  setRunUpdateHeatingCostSplit
} from '../../../../../redux/action/General/generalActions';
import Tab, { Form, Row, Col, Container } from 'react-bootstrap';
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  TableContainer
} from '@material-ui/core';
// import MobileUtilityCostAllocation from './MobileUtilityCostAllocation';
import Autoload from '../../../../Autoload';
import tap from '../../../../../assets/images/module/general/TapIconBlue.svg';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import PaidLockIcon from '../../../../../assets/images/module/general/PaidLockIcon.svg';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import moment from 'moment';
import { data } from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import CrossIcon from '../../../../../assets/images/module/general/CrossIcon.svg';
import GeneralDataHandler from '../../../../../neoverv/helpers/DataHandler/General';
import ReceiptDetailModal from '../CustomModal/ReceiptDetailModal';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { config } from '../../../../config/Setting';
import LockIcon from '../../../../../assets/images/module/general/LockIconRed.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Box, Button, Group, Skeleton, Stack, Overlay } from '@mantine/core';
import AddPropertyHeatingModal from '../CustomModal/AddPropertyHeatingModal';
import { hideNotification, showNotification } from '@mantine/notifications';
import HeadingContainerNew from '../HeadingContainerNew';
import breadcrumbsContent from '../../../../../assets/breadcrumbs.json';
import HelpLogo from '../../../../../assets/images/module/general/book.svg';
import TourIcon from '../../../../../assets/images/module/general/TourIcon.svg';
import VideoTourIcon from '../../../../../assets/images/module/general/VideoTourIconBlue.svg';
import BlurHeatingUB from '../../../../../assets/images/module/general/BlurHeatingUB.svg';
import AlertModal from '../CustomModal/AlertModal';

const { loader } = Autoload.MainHelper;

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default];
var langnewKeys = Setting[Setting.language_default].newKeys;
const { checkProperty, yearDifferenceMoment } = Autoload.MainHelper;
const tableHeaderStyling = {
  borderBottom: 'none',
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '20px',
  color: '#727293',
  paddingBottom: '0px',
  fontFamily: 'Inter,sans-serif'
}

//const MOBILE_SIZE = 766;
export class HeatingCostAllocation extends Component {
  state = {
    heatingSwitch: false,
    currentRecord: {},
    editReceiptOpen: false,
    showModal: false,
    imageOpen: false,
    //isMobile: window.innerWidth <= MOBILE_SIZE,
    receipts: [],
    // filter: true,
    heatingCostPositions: [],
    heatingCostModal: false,
    utilityData: {
      date: '',
      recordStatus: '',
      isSecured: false
    },
    addReceiptOpen: false,
    currentCp: null,
    billingPeriodId: '',
    dropdownOpen: false,
    skeletonLoading: false,
    openPropertyHeatingModal: false,
    utilityBillingFreshData: null,
    ubData: this.props.utilityBillingData[0],
    opentotalOccupantsCountNullAlert: false,
    allowUpdateHeatingCostSplit: true,
    openFreezeModal: false,
    freezeAlertHeading: '',
    freezeAlertMessage: '',
  };

  componentDidMount() {
    // console.log('mount')
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'block';
    //loader(true);
    this.componentDidMountStuff();
    // this.handleResize();
    // window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps){
    if(prevProps.utilityBillingData.length === 0 && this.props.utilityBillingData.length > 0){
      this.componentDidMountStuff();
    }
  }

  componentDidMountStuff=()=>{
    const {runUpdateTenantOccupations, setRunUpdateTenantOccupations} = this.props;
    let data = this.props.utilityBillingData.find((ub) => ub.id == this.props.currentDataId);
    console.log('componentDidMount: data: ', data);
    this.setState({billingPeriodId: data?.billingPeriodId, skeletonLoading: true, ubData: data});
    GlobalServices._getAllocationKeysByProperty(this, '_handle_allocation_keys_response', this.props.propertySelected);
    // let diff = yearDifferenceMoment(data.date.split('-')[0]);
    let utilityDate = data?.date;

    if(utilityDate?.includes('T')){
      let dates = data.date.split(' - ');
      utilityDate = `${moment(dates[0]).format('DD.MM.YYYY')} - ${moment(dates[1]).format('DD.MM.YYYY')}`
    }
    if (data !== undefined) {
      this.setState({ utilityData: { date: utilityDate, recordStatus: data.recordStatus, isSecured: data.isSecured }},()=> {
        if (this.props.currentDataId) {
          //loader(true);
          if(data.isSecured === false){
            if(runUpdateTenantOccupations){
              this.updateTenantOccupations();
            }else{
              this.setState({skeletonLoading: true});
              this.updateConsumptions();
            }
          }else{
            this.getSwitchStatus();
          }
        }
        // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Invalid Billing Id');
        else console.log('invalid billing id ');
      });
    }else {
      //loader(false);
      this.setState({skeletonLoading: false});
    }
  }

  componentWillUnmount(){
    hideNotification('knownErrors');
  }
  _handle_allocation_keys_response(response) {
    console.log(response, 'allocation keys in meter');
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
    }
    const keys = GeneralDataHandler.getAllocationKeys(response.result);
    // const allocationKeys = keys.filter((keys) => !keys.metered);
    this.setState({
      allocationKeys: keys.filter((key)=> key.meterType !== 'PROPERTY'),
      // meterData: {
      //   ...this.state.meterData,
      //   allocationKey: allocationKeys.length > 0 && allocationKeys[0].id,
      //   allocationKeyName: allocationKeys.length > 0 && allocationKeys[0].allocationKeyName,
      // },
    });
  }

  updateHeatingCostSplit = (heatingSwitch, onSwitchChange= false, onUpdatePropertyHeatingParams= false, heatingCostPositionsNotAvailable= false) => {
    console.log('this.state.allowUpdateHeatingCostSplit: ', heatingSwitch, this.state.allowUpdateHeatingCostSplit);
    (this.props.propertySelected.heatingType === 'central with warm water' || this.props.propertySelected.heatingType === 'central without warm water') && heatingSwitch ? 
    this.state.allowUpdateHeatingCostSplit ? this.runUpdateHeatingCostSplit(onUpdatePropertyHeatingParams, heatingCostPositionsNotAvailable)
      : 
      onSwitchChange ? heatingSwitch && this.callApis() : this.callApis()
    :
    onSwitchChange ? heatingSwitch && this.callApis() : this.callApis(false, false, true);
  }

  runUpdateHeatingCostSplit=(onUpdatePropertyHeatingParams, heatingCostPositionsNotAvailable)=>{
    if(this.props.runUpdateHeatingCostSplit){
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'updateHeatingCostSplit/' + this.props.currentDataId,
        'PUT',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleUpdateHeatingCostSplitResponse',
        {onUpdatePropertyHeatingParams: onUpdatePropertyHeatingParams, heatingCostPositionsNotAvailable: heatingCostPositionsNotAvailable},
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        },
        false,
        false,
        // <span style={{cursor: 'pointer', color: 'rgb(85, 85, 255)'}} onClick={()=> {this.setState({heatingCostModal: true}); hideNotification('knownErrors');}}>
        //   {'Werte anpassen.'}
        // </span>
      )
    }else{
      this.callApis();
    }
  }

  handleUpdateHeatingCostSplitResponse = (response) => {
    !response.status && console.log('Update Heating Cost Split Failed');
    console.log('workingHeatingCostSplit');
    if(response.other.onUpdatePropertyHeatingParams){
      this.callApis(response.other.onUpdatePropertyHeatingParams);
    }else if(response.other.heatingCostPositionsNotAvailable){
      this.callApis(false, response.other.heatingCostPositionsNotAvailable);
    }
    else{
      this.callApis();
    }

    this.props.setRunUpdateHeatingCostSplit(false);
    // if(response.other.heatingCostPositionsNotAvailable){
    //   this.openHeatingCostModal();
    // }
  }

  updateConsumptions = () => {
    const {runUpdateConsumptions} = this.props;
    if(runUpdateConsumptions){
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.ub_api_base_url + 'updateConsumptions/' + this.props.currentDataId,
        'PUT',
        null,
        null,
        false,
        true,
        false,
        this,
        'handleUpdateConsumptionsResponse',
        null,
        false,
        '',
        {
          Authorization: Autoload.Utils.getAuthToken(),
        }
      );
    }else{
      this.getSwitchStatus();
    }
    
  };
  handleUpdateConsumptionsResponse = (response) => {
    !response.status && console.log('Update Consumptions Failed');
    console.log('workingConsumptions');
    this.getSwitchStatus();
    this.props.setRunUpdateConsumptions(false);
  };

  updateConsumptionsByCostPositionId = (costPositionIds) => {
    this.setState({skeletonLoading: true});
    //loader(true);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'updateConsumptions/' + this.props.currentDataId+'?costPositionIds='+costPositionIds,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleUpdateConsumptionsByCostPositionIdResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleUpdateConsumptionsByCostPositionIdResponse = (response) => {
    !response.status && console.log('Update Consumptions Failed');

    //loader(false);
    this.setState({skeletonLoading: false});
    // this._getHeatingCostPositions();
    //GlobalServices._getAllocationKeysByProperty(this, '_handleAllocationKeysResponse', this.props.propertySelected);
    //this.updateReceiptAssignments();
    
    //this._getCostPositions();
  };

  updateTenantOccupations = () => {
    console.log('runUpdateTenantOccupations: ', this.props.runUpdateTenantOccupations);
    this.setState({skeletonLoading: true});
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'updateTenantOccupations/' + this.props.currentDataId,
      'PUT',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleUpdateTenantOccupationsResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handleUpdateTenantOccupationsResponse = (response) => {
    const {setRunUpdateTenantOccupations} = this.props;
    !response.status && console.log('Update Tenant Occupations Failed');
    console.log('workingTenantOccupations');
    this.updateConsumptions();
    setRunUpdateTenantOccupations(false);
  };


  callApis(onUpdatePropertyHeatingParams= false, heatingCostPositionsNotAvailable= false, stopLoading=false) {
    const {propertySelected} = this.props;
    ((propertySelected.heatingType && (propertySelected.heatingType === 'central with warm water' || propertySelected.heatingType === 'central without warm water')) && this._getHeatingCostPositions(onUpdatePropertyHeatingParams, heatingCostPositionsNotAvailable))
    // this.state.utilityBillingFreshData.hasCentralWarmWaterAmount) 
    // ? 
    // (propertySelected.energyFuelType && propertySelected.centralWarmWaterAllocationKey && this._getHeatingCostPositions(onUpdatePropertyHeatingParams))
    // : 
    // (propertySelected.energyFuelType && this._getHeatingCostPositions(onUpdatePropertyHeatingParams));
    GlobalServices._getReceiptsByProperty(this, '_handleReceiptsResponse', this.props.propertySelected);

    this.getUtilityBillings(stopLoading);
  }
  _handleReceiptsResponse(response) {
    if (response.status === false) {
      Autoload.Redirection.redirection_error();
      //loader(false);
      this.setState({skeletonLoading: false});
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Receipts not found against this property!');
    }
    if (response.result.length > 0) {
      console.log('=============================================================');
      console.log(response.result);
      let formattedArr = response.result
        .filter((receipt) => checkProperty(receipt, 'utilityBillingRelevant') == true)
        .map((receipt) => ({
          id: checkProperty(receipt, 'id'),
          receiptName: checkProperty(receipt, 'name'),
          date: moment(checkProperty(receipt, 'receiptDate')).format('DD.MM.YYYY'),
          proRataDays: 123,
          selectedCostTypes: checkProperty(receipt, 'selectedCostTypes'),
        }));
      this.setState({
        receipts: formattedArr,
      });
      // console.log("__Receipts__", formattedArr)
    }
    this.props.propertySelected.heatingType === 'decentralized' //&& loader(false) 
    && this.setState({skeletonLoading: false});
  }

  getSwitchStatus = () => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'utilityBillings/' + this.props.currentDataId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleSwitchResponse',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  getUtilityBillings = (stopLoading) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'utilityBillings/' + this.props.currentDataId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleGetUtilityBillingsResponse',
      {stopLoading},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handleGetUtilityBillingsResponse(response){
    if(response.status){
      let ubData = this.props.utilityBillingData.find((ub)=> ub.id == response.result.id) === undefined ? 
      [...this.props.utilityBillingData, {...response.result }] 
      :
      this.props.utilityBillingData.map((ub)=> {
        if(ub.id === response.result.id){
          return {...ub, ...response.result};
        }else{
          return ub;
        }
      });
      this.props.setUtilityBillingData(ubData);
      this.setState({ubData: {...this.state.ubData, ...response.result}, utilityBillingFreshData: response.result});
      if(response.other.stopLoading){
        this.setState({skeletonLoading: false});
      }
    }else{
      if(response.other.stopLoading){
        this.setState({skeletonLoading: false});
      }
    }
  }
  updateSwitchStatus = (data, manual=false) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + `utilityBillings/${this.props.currentDataId}/activateHeatingCosts/`,
      'PUT',
      { status: data.heatingCostsAllocated },
      null,
      false,
      true,
      false,
      this,
      '_handleUpdateSwitchResponse',
      {manual},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handleUpdateSwitchResponse = (response) => {
    if (!response.status) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_Heating_Cost_switch_response);
    }
    //this.getUtilityBillings();
    this.updateHeatingCostSplit(this.state.heatingSwitch, true);
    this.props.setHeatingAllocation(this.state.heatingSwitch);
    if(response.other.manual){
      this.getUtilityBillings();
    }
    // window.open(window.location.href, '_self');
  };
  _handleSwitchResponse = (response) => {
    console.log('_handleSwitchResponse: ', response);
    console.log('this.props.isEdit: ', this.props.isEdit);
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_Heating_Cost_switch_response);
    }
    if(response.status && response.result){
      let {ubData} = this.state;
      ubData = {
        ...ubData,
        ...response.result
      }
      this.setState({utilityBillingFreshData: response.result, ubData});
      
    }

    // if (this.props.isEdit && checkProperty(response.result, 'heatingCostsAllocated') === false) {
    
    if(this.props.currentSubscription?.subscriptionName === 'Starter' && this.state.utilityData.isSecured === false){
      if(response.result.heatingCostsActivated === true){
        this.updateSwitchStatus({heatingCostsAllocated: false});
      }
      this.updateUBData({...response.result, isVATHidden: true});
      this.props.setHeatingAllocation(false);
    }else if (checkProperty(response.result, 'heatingCostsActivated')) {
      this.props.setHeatingAllocation(true);
    }else{
      this.props.setHeatingAllocation(false);
    }
    // updating local state
    this.setState({
      //heatingSwitch: this.props.isEdit !== false ? checkProperty(response.result, 'heatingCostsActivated', false) : true,
      heatingSwitch: this.props.currentSubscription?.subscriptionName === 'Starter' ? false : checkProperty(response.result, 'heatingCostsActivated', false),
    }, ()=> {

      console.log('testing_12345: ', (this.props.propertySelected.heatingType === 'central with warm water' && response.result.hasCentralWarmWaterAmount) ? 
      response.result.totalHeatingCost && response.result.heatingEnergyAmount && response.result.energyAmountWarmWater && this.state.heatingSwitch 
      :
      response.result.totalHeatingCost && response.result.heatingEnergyAmount && this.state.heatingSwitch, response.result.totalHeatingCost && response.result.heatingEnergyAmount && this.state.heatingSwitch);

      this.state.utilityData?.isSecured === false ? 
      (this.updateHeatingCostSplit(
        // (this.props.propertySelected.heatingType === 'central with warm water' && response.result.hasCentralWarmWaterAmount) ? 
        // response.result.totalHeatingCost && response.result.heatingEnergyAmount && response.result.energyAmountWarmWater && this.state.heatingSwitch 
        // :
        // response.result.totalHeatingCost && response.result.heatingEnergyAmount && 
        this.state.heatingSwitch))
      :
      this.callApis();
      //!this.state.heatingSwitch && loader(false);
      !this.state.heatingSwitch && this.setState({skeletonLoading: false});
      let updatedState = this.state.heatingSwitch;
      // updating local state & redux store
      //this.setState({ heatingSwitch: updatedState });
      //!this.props.isEdit && response.result.heatingCostsActivated !== this.state.heatingSwitch ? this.updateSwitchStatus({ heatingCostsAllocated: updatedState }) : this.props.setHeatingAllocation(this.state.heatingSwitch);
    }
    );
    // updating redux store
    // this.props.setHeatingAllocation(
    //   this.props.isEdit ? checkProperty(response.result, 'heatingCostsActivated', false) : true
    // );
    
    this.props.setUtilityBillingData(
      this.props.utilityBillingData.find((ub)=> ub.id == response.result.id) === undefined ? 
      [...this.props.utilityBillingData, {...response.result }] 
      :
      this.props.utilityBillingData.map((ub)=> {
        if(ub.id === response.result.id){
          return {...ub, ...response.result};
        }else{
          return ub;
        }
      })
    );
    //loader(false);
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'none';
  };


  updateUBData=(data)=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'utilityBillings/' + this.props.currentDataId,
      'PUT',
      data,
      null,
      false,
      true,
      false,
      this,
      '_handleUtilityPut',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      },
      false,
      false
    );
  }

  _handleUtilityPut(response){
    
  }
 
  _getHeatingCostPositions = (onUpdatePropertyHeatingParams= false, heatingCostPositionsNotAvailable= false) => {
    this.state.heatingSwitch && Autoload.HttpRequestAxios.HttpRequest(
      // Autoload.Setting.ub_api_base_url + 'costPositions?UtilityBillingId=11424447472447472424120',
      Autoload.Setting.ub_api_base_url +
      'costPositions?filter=heating&offset=0&results=100&utilityBillingId=' +
      this.props.currentDataId,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handleHeatingCostPositionResponse',
      {onUpdatePropertyHeatingParams: onUpdatePropertyHeatingParams, heatingCostPositionsNotAvailable: heatingCostPositionsNotAvailable},
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handleHeatingCostPositionResponse = (response) => {
    if (response.status === false) {
      // Autoload.Redirection.redirection_error();
      if (this.props.propertySelected?.heatingType !== 'decentralized') {
        //Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_no_cost_position_found);
        showNotification({message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.', icon: <img src={RedCross} alt='red-cross'/>});
        //loader(false);
        this.setState({skeletonLoading: false});
      }
    }
    let data = [];
    if (Array.isArray(response.result) && response.result.length > 0) {
      data = response.result.map((cp, id) => {
        console.log('response: ', this.state.allocationKeys.find((ak)=> ak.id === checkProperty(cp, 'allocationKeyId')));
        // if(this.props.propertySelected.propertyType === 'CONDOMINIUM' && checkProperty(cp, 'allocationKeyName') === 'Personenanzahl' && !this.props.propertySelected.totalOccupantsCount){
        //   console.log('_handleHeatingCostPositionResponse: ', cp, this.props.propertySelected.totalOccupantsCount);
        //   this.setState({opentotalOccupantsCountNullAlert: true});
        // }
        return {
          id: checkProperty(cp, 'id'),
          costTypeId: checkProperty(cp, 'costTypeId'),
          costTypeName: checkProperty(cp, 'costTypeName'),
          amount: checkProperty(cp, 'amount'),
          allocationKeyId: checkProperty(cp, 'allocationKeyId'),
          allocationKeyName: checkProperty(cp, 'allocationKeyName'),
          meterData: checkProperty(cp, 'consumptionDataAvailable'),
          costTypeIdentifier: checkProperty(cp, 'costTypeIdentifier'),
          allocationKey: this.state.allocationKeys.find((ak)=> ak.id === checkProperty(cp, 'allocationKeyId')),
          // allocationKeyName: '',
          // meterData: '',
          selectedReceipt: [],
          utilityBillingId: checkProperty(cp, 'utilityBillingId'),
          orderNo: checkProperty(cp, 'orderNo'),
          allocationKeyType: checkProperty(cp, 'allocationKeyType'),
          vatAmount: cp.vatAmount,
          laborCost: cp.laborCost
        };
      });

      console.log('Data from Cost Position API: ', data);
      this.setState({ heatingCostPositions: data }, () => {
        response.other.onUpdatePropertyHeatingParams && this.openHeatingCostModal();
        response.other.heatingCostPositionsNotAvailable && this.openHeatingCostModal();
        this.setState({skeletonLoading: false});
        //call receipt api for record which have no allocation key and meter data
        // data.forEach((record, i) => {
        //   if (record.allocationKeyName === '') {
        //     Autoload.HttpRequestAxios.HttpRequest(
        //       Autoload.Setting.ub_api_base_url + 'receiptAssignments/' + record.id,
        //       'GET',
        //       null,
        //       null,
        //       false,
        //       true,
        //       false,
        //       this,
        //       '_handleGetReceiptResponse',
        //       { cpId: record.id, data: record, isLastResponse: data.length === i + 1 },
        //       false,
        //       '',
        //       {
        //         Authorization: Autoload.Utils.getAuthToken(),
        //       }
        //     );
        //   }
        // });
      });
      let url = new URL(window.location.href);
      this.setState({heatingCostModal: JSON.parse(url.searchParams.get("openHeatingForm")) ? JSON.parse(url.searchParams.get("openHeatingForm")) : this.state.heatingCostModal},
      ()=>{
        if(JSON.parse(url.searchParams.get("openHeatingForm")) === true){
          this.props.history.push(`utility?currentBillingId=${url.searchParams.get("currentBillingId")}&ub-step=${url.searchParams.get("ub-step")}&openHeatingForm=false`);
        }
      }
      );
    }
    // else Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newkeys.text_error_no_cost_position_found);
  };
  bind_list_receipt_data = () => {
    let propertyid = this.props.propertySelected && this.props.propertySelected.id;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'property/' + propertyid,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_data',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_get_list_data(response) {
    console.log(response, 'handle get list bind receipt');
    console.log(this.state.currentCp);
    if (this.state.currentCp) {
      this.onReceiptSelect({ id: response.result[0]?.id, name: response.result[0]?.name }, this.state.currentCp);
    }
    // if (!response.status === true && !response.statuscode === 200) {
    //   // Autoload.Redirection.redirection_error();
    //   Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
    // }
  }

  onReceiptSelect = (receipt, cp) => {
    // console.clear()
    console.log(receipt);
    //loader(true);
    // console.log('receipt selection', selectedList, selectedItem)
    let addedReceipt = {
      receiptId: checkProperty(receipt, 'id'),
      receiptName: checkProperty(receipt, 'name'),
      // receiptDate: checkProperty(selectedItem, 'date'),
      proRataDays: 123,
      costPositionId: cp.id,
      id: null,
    };
    console.log('addedReceipt', addedReceipt);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'receiptAssignments',
      'POST',
      addedReceipt,
      null,
      false,
      true,
      false,
      this,
      '_handlePostReceipt',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
    // this.setState({dataValues:{...this.state.dataValues, selectedReceipt: selectedList }},()=>this.updateStore())
  };
  _handlePostReceipt = (response) => {
    var loader = document.getElementById('hello-housing-loader');
    if (response.status == false) {
      Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_not_found);
    }
    // console.log('response of post receipt', response)
    this._getHeatingCostPositions();
    //loader.style.display = 'none';
    //loader(false);
    this.setState({skeletonLoading: false});
  };

  abc = (id) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.ub_api_base_url + 'receiptAssignments/' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handle_get_list_data',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  handle_zz(response) {
    console.log(response, 'handle get list bind receipt');
    // if (!response.status === true && !response.statuscode === 200) {
    //   // Autoload.Redirection.redirection_error();
    //   Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Error :  ' + response.message);
    // }
  }

  _handleGetReceiptResponse(response) {
    if (response.status === false) {
      // Autoload.Redirection.redirection_error();
      //loader(false);
      this.setState({skeletonLoading: false});
      // Autoload.DataHandlerGeneral.GeneralErrorMessage(true, 'Receipt Assignment not Found Against Cost position!');
    } else{
      if (response.result.length > 0) {
        let updatedRecord = {
          ...response.other.data,
          selectedReceipt: response.result.map((receipt) => ({
            proRataDays: checkProperty(receipt, 'proRataDays'),
            receiptAssignmentId: checkProperty(receipt, 'id'),
            id: checkProperty(receipt, 'receiptId'),
            receiptName: checkProperty(receipt, 'receiptName'),
          })),
        };
  
        this.setState(
          {
            heatingCostPositions: [
              ...this.state.heatingCostPositions.filter((i) => i.id !== updatedRecord.id),
              updatedRecord,
            ],
          },
          () => {
            //response.other.isLastResponse && loader(false);
          }
        );
      } //else response.other.isLastResponse && loader(false);
    }
  }

  getTableLabels = () => {
    if (this.state.heatingCostPositions.length > 0) {
      return (
        <TableRow //className="table-heading utility-cost-head"
        className='utility-table-row-head'
        style={{boxShadow: 'none'}}
        >
          <TableCell //className="cp-label-cost d-none d-md-table-cell"
          className='ub-table-head-cell-cost-type'
          style={tableHeaderStyling}
          >{lang.utility.text_cost_type}</TableCell>
          <TableCell //className="cp-label-amount d-none d-md-table-cell"
          className='ub-table-head-cell-amount'
          style={tableHeaderStyling}
          >{lang.utility.text_amount}</TableCell>
          <TableCell //className="cp-label-key d-none d-md-table-cell"
          className='ub-table-head-cell-allocation-key'
          style={tableHeaderStyling}
          >{lang.utility.text_allocation_key}</TableCell>
          <TableCell //className="cp-label-meter d-none d-md-table-cell"
          className='ub-table-head-cell-consumption'
          style={tableHeaderStyling}
          >{lang.utility.text_meter_distributor_value}</TableCell>
          {/* <TableCell //className="cp-label-receipt d-none d-md-table-cell"
          className='ub-table-head-cell-receipt'
          style={tableHeaderStyling}
          >{lang.utility.text_Receipt}</TableCell> */}
        </TableRow>
      );
    }
  };

  onDropdownOpen = (value= false) => {
    this.setState({dropdownOpen: value}, ()=> console.log('this.state.dropdownOpen: ', this.state.dropdownOpen));
  }

  getTableData = () => {
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'block';
    //loader(true);
    //this.setState({skeletonLoading: true});
    console.log('this.state.heatingCostPositions: ', this.state.heatingCostPositions);
    const {isMobile, isTablet, runUpdateTenantOccupations} = this.props;
    const jsx = this.state.heatingCostPositions.length !== 0 ? this.state.heatingCostPositions
      .sort((a, b) => (a['orderNo'] || '').toString().localeCompare((b['orderNo'] || '').toString()))
      .map((cp, i) => {
        if((this.state.heatingCostPositions.length - 1) === i){
          //loader(false);
          //this.setState({skeletonLoading: false});
        } 
        return !isMobile ? (
            <HeatingCostAllocationRow
              toggleViewDetailsModal={this.toggleViewDetailsModal}
              updateCp={this._getHeatingCostPositions}
              fetchAllocationKeysByProperty={()=> GlobalServices._getAllocationKeysByProperty(this, '_handle_allocation_keys_response', this.props.propertySelected)}
              data={cp}
              isMobile={isMobile}
              isTablet={isTablet}
              cpId={this.getCpId()}
              receiptsArr={this.state.receipts}
              firstRow={i == 0}
              costTypeId={cp.costTypeId}
              utilityData={this.state.utilityData}
              timeFrame={this.state.utilityData.date}
              allocationKeys={this.state.allocationKeys}
              billingPeriodId={this.state.billingPeriodId}
              callApis={() => GlobalServices._getReceiptsByProperty(this, '_handleReceiptsResponse', this.props.propertySelected)}
              abc={this.abc}
              editReceiptOpen={this.state.editReceiptOpen}
              onDropdownOpen={this.onDropdownOpen}
              callUpdate={()=>{
                if(runUpdateTenantOccupations){
                  this.updateTenantOccupations();
                }else{
                  this.setState({skeletonLoading: true});
                  this.updateConsumptions();
                }
              }}
              updateMeterData={(meterData, costPositionId)=> this.setCostPositionsAfterUpdate(cp, meterData)}
              opentotalOccupantsCountNullAlert={()=> this.setState({opentotalOccupantsCountNullAlert: true})}
              history={this.props.history}
              updateConsumptionsByCostPositionId={(costPositionIds)=> this.updateConsumptionsByCostPositionId(costPositionIds)}
            />
        ) : (
          <MobileHeatingCostAllocation
            row={cp}
            costTypesArr={this.state.costTypes}
            allocationsArr={this.state.allocationKeys}
            receiptsArr={this.state.receipts}
            isMobile={this.props.isMobile}
            updateCp={this._getHeatingCostPositions}
            // utilityBillingId={this.props.currentBillingId}
            costTypesBackFunction={() =>
              GlobalServices._getCostTypeByProperty(this, '_handleCostTypeResponse', this.props.propertySelected)
            }
          />
        );
        
      })
      :
      //loader(false) && 
      //this.setState({skeletonLoading: false});
      null;
    //loader.style.display = 'none';
    return jsx;
  };

  openHeatingCostModal = () => {
    if (Setting.UB_debug) {
      let meterCheck = false;

      this.state.heatingCostPositions.map((record) => {
        if (checkProperty(record, 'meterData', '') === '') {
          meterCheck = true;
        }
      });

      if (meterCheck) {
        toast.dark('No Meter data recieved', {
          position: 'top-center',
          autoClose: false,
          className: 'neoverv-toast',
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
    }
    this.setState({ heatingCostModal: true });
  };
  closeHeatingCostModal = () => {
    this.setState({ heatingCostModal: false, allowUpdateHeatingCostSplit: true });
  };
  getCpId = () => {
    const cp = this.state.heatingCostPositions.find((cp) => cp.costTypeName === 'Heizung & Heizungswartung');
    return cp ? cp : null;
  };  
  handleHeatingSwitchChange = () => {
    let updatedState = !this.state.heatingSwitch;

    // updating local state & redux store
    this.setState({ heatingSwitch: updatedState });
    this.props.setHeatingAllocation(updatedState);
    this.updateSwitchStatus({ heatingCostsAllocated: updatedState }, true);
    //updatedState && this.callApis(); // getting costpositions and receipt data when switch enabled again
  };
  // handleResize = () => {
  //   if (window.innerWidth <= MOBILE_SIZE) {
  //     this.setState({ isMobile: true });
  //   } else {
  //     this.setState({ isMobile: false });
  //   }
  // };

  getAllocationStatusCheck = () => {
    let check1 = false;
    let check2 = false;
    let check3 = false;
    const rhc = this.state.heatingCostPositions?.filter((cp) => cp.costTypeIdentifier === 'ROOM_HEATING_CONSUMPTION');
    const wwc = this.state.heatingCostPositions?.filter((cp) => cp.costTypeIdentifier === 'WARMWATER_CONSUMPTION');
    if (rhc.length && wwc.length) {
      for (let i = 0; i < rhc.length; i++) {
        if (!rhc[i].allocationKeyName.includes('Wärmemenge')) {
          check1 = true;
        }
      }
      for (let i = 0; i < wwc.length; i++) {
        if (wwc[i].allocationKeyName.includes('Warmwasserverbrauch')) {
          check2 = true;
        }
      }
    }
    if (this.props.utilityBillingData[0] && !this.props.utilityBillingData[0].totalHeatingCost) {
      check3 = true;
    }
    return check1 && check2 && check3;
  };

  // Receipt Show Model Related --- Start
  closeShow = () => {
    this.setState({ showModal: false });
  };

  toggleViewDetailsModal = (recordId) => {
    this.getRecord(recordId);
  }
  getRecord = (id, openShow=true) => {
    console.log('Heating: getRecord: id:', id);
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.api_base_url + config.receipts + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handle_get_record',
      {id, openShow},
      false,
      '',
      {
        // 'Content-Type': 'application/json',
        'Content-Type': 'text/xml',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
    // setCurrentRecord(record)
  };

  _handle_get_record = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        langnewKeys.text_error_not_found
        // + response.message
      );
    }
    console.log('Heating: _handle_get_record: response:', response);
    this.setState({currentRecord: response.result}, ()=> response.other.openShow && this.openShow(response.other.id))
  }

  closeEditReceipt = () => {
    this.setState({ editReceiptOpen: false });
  };

  handleEditClick = () => {
    this.closeShow();
    setTimeout(() => this.toggleEditReceiptModal(), 500);
  };

  toggleEditReceiptModal = () => {
    this.setState({ editReceiptOpen: !this.state.editReceiptOpen });
  };

  toggleImageModal = () => {
    this.setState({ imageOpen: !this.state.imageOpen });
  };

  openShow = (id) => {
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'block';
    loader(true);

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'attachment/' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handle_get_show_file',
      id,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  _handle_get_show_file = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        langnewKeys.text_error_not_found
        // + response.message
      );
    }

    if(response && response.result.includes('pdf')){
      fetch(Autoload.Setting.rm_api_base_url + 'attachment/' + response.other +'/download',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          'Authorization': Autoload.Utils.getAuthToken()
        }
      })
            .then(response => {
                response.blob()
                .then(blob => {
                  console.log('blob: ', blob)
                  this.setState({
                    currentRecord: { ...this.state.currentRecord, file_name: blob.size > 0 ? blob : '' },
                    showModal: true,
                  });
                  loader(false);
                  })
                  //var loader = document.getElementById('hello-housing-loader');
                  //loader.style.display = 'none';
              })
    } else {
      this.setState({
        currentRecord: { ...this.state.currentRecord, file_name: response.result != null ? response.result : '' },
        showModal: true,
      });
      loader(false);
      //var loader = document.getElementById('hello-housing-loader');
      //loader.style.display = 'none';
    }
  };
  // Receipt Show Model Related --- End
  renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      <span>Betriebskostenabrechnung ist festgeschrieben und wird nicht automatisch aktualisiert. </span>
      <span 
      onClick={() => 
        this.setState({
          openFreezeModal: true, 
          freezeAlertHeading: this.state.utilityData.isSecured ? lang.utility.text_freeze_alert_heading : lang.utility.text_unfreeze_alert_heading, 
          freezeAlertMessage: this.state.utilityData.isSecured ? lang.utility.text_freeze_alert_message : (<span>{lang.utility.text_unfreeze_alert_message} <a href="https://wissen.hellohousing.de/was-bedeutet-eine-festschreibung-der-betriebskostenabrechnung" target="_blank">(mehr erfahren)</a>.</span>)
        })}
      style={{cursor: 'pointer', fontWeight: 'bold', color: '#5555FF'}}>
          Festschreibung aufheben.
      </span>
    </Tooltip>
  );

  setCostPositionsAfterUpdate=(cp, meterData=null)=>{
    let {heatingCostPositions} = this.state;

    heatingCostPositions = heatingCostPositions.map((costPosition)=> costPosition.id === cp.id ?
    {
      ...cp,
      id: checkProperty(cp, 'id'),
      costTypeId: checkProperty(cp, 'costTypeId'),
      costTypeName: checkProperty(cp, 'costTypeName'),
      amount: checkProperty(cp, 'amount'),
      allocationKeyId: checkProperty(cp, 'allocationKeyId'),
      allocationKeyName: checkProperty(cp, 'allocationKeyName'),
      meterData: meterData ? meterData : checkProperty(cp, 'consumptionDataAvailable'),
      selectedReceipt: [],
      utilityBillingId: checkProperty(cp, 'utilityBillingId'),
      orderNo: checkProperty(cp, 'orderNo'),
      allocationKeyType: checkProperty(cp, 'allocationKeyType'),
    }
    :
    costPosition);
    this.setState({heatingCostPositions}, ()=> {
      this.props.setCostPositions(heatingCostPositions);
    });
  }

  render() {
    const { className, utilityBillingData, propertySelected, currentSubscription, accountData, runUpdateTenantOccupations } = this.props;
    const { utilityData, showModal, currentRecord, editReceiptOpen, imageOpen, dropdownOpen, skeletonLoading, openPropertyHeatingModal, utilityBillingFreshData, ubData, opentotalOccupantsCountNullAlert, openFreezeModal, freezeAlertHeading, freezeAlertMessage } = this.state;
    const { isMobile, isTablet } = this.props;
    //const ubData = utilityBillingData.find((ub)=> ub.id === this.props.currentDataId);
    this.getAllocationStatusCheck();
    console.log('this.props.utilityBillingData: ', this.props.utilityBillingData);
    console.log('propertySelected: ', propertySelected);
    console.log('utilityBillingFreshData: ', utilityBillingFreshData, ubData);
    console.log('currentSubscription: ', currentSubscription);

    console.log('ubData?.heatingCostsActivated: ', ubData);

    console.log('runUpdateTenantOccupations: ', runUpdateTenantOccupations);
    return (
      <div className="view-utility-billing" style={{height: '100%', overflow: 'hidden'}}>
        <HeadingContainerNew
          heading={lang.utility.text_utility_billing}
          subheading={
            <>
              <span>{utilityData.date}</span>
              {utilityData.isSecured && 
              <OverlayTrigger placement="bottom" delay={{ show: 450, hide: 1200 }} overlay={this.renderTooltip}>              
                    <img src={LockIcon} width='16px' style={{marginBottom: 4, marginLeft: 4}}/>                
              </OverlayTrigger>}
            </>
          }
          className="profile-heading tenant-head utility-billing-head"
          headingIcon={CrossIcon}
          iconHandler={
            () => {
              this.props.setUtilityBillingData([]);
              this.props.history.push('/utility-billing');
            }
            // this.goBackIcon()
          }
          subheadingClassName="utility-subheading"
          subheadingbadge={
            <span className={utilityData.recordStatus === 'last' ? 'last-period-badge' : 'no-recent-period-bagde'}>
              {utilityData.recordStatus === 'last' ? lang.utility.text_last_billing_period : ''}
            </span>
          }
          classNameRightContent={'heating-screen-next-button'}
          rightContent={
            <div className="btn-container utility-bility-button">
              {/* <ColorButton text={lang.utility.text_go_back} className={`gray-btn `} buttonConfig={{ disabled: true }} /> */}
              <ColorButton
                onClick={() => {
                  // this.moveForward();
                  if (!skeletonLoading) {
                    this.props.goNext();
                    this.props.history.push(`/utility-billing/utility?currentBillingId=${this.props.currentDataId}&ub-step=utility-cost`, { isEdit: true });
                  } 
                  // else {
                  //   Autoload.DataHandlerGeneral.GeneralErrorMessage(
                  //     true,
                  //     lang.newKeys.text_error_calculate_heating_cost
                  //   );
                  // }
                }}
                text={lang.utility.text_next_utility_bills}
                className="purple-btn"
                showLoader={skeletonLoading}
              />
            </div>
          }
          breadcrumbs={
          <Group spacing={10} noWrap>
          {breadcrumbsContent.map((item)=> item.area.includes('ub-step=heating-cost') && 
          item.breadcrumbs.map((breadcrumb)=>
          breadcrumb.type === 'tour' ? breadcrumb.condition === 'rental_unit_created' && breadcrumb.view === 'desktop' &&
          <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('startTour', breadcrumb.id)}>
            {skeletonLoading ?
            <Skeleton visible={skeletonLoading} style={{width: '180px', height: '20px'}}/>
            :
            <Group spacing={0} noWrap>
              <img src={breadcrumb.subType === 'video' ? VideoTourIcon : TourIcon} style={{width: breadcrumb.subType === 'video' && '13px'}}/>
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', whiteSpace: 'nowrap'}}>{breadcrumb.label}</span>
            </Group>}
          </div>
          :
          breadcrumb.view === 'desktop' &&
          <div style={{display: 'flex', width: 'auto', alignItems: 'center', cursor: 'pointer', marginRight: '22px'}} onClick={()=> window.Intercom('showArticle', breadcrumb.id)}>
            {skeletonLoading ?
            <Skeleton visible={skeletonLoading} style={{width: '230px', height: '20px'}}/>
            :
            <Group spacing={0} noWrap>
              <img src={HelpLogo} style={{}}/>
              <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', whiteSpace: 'nowrap'}}>{breadcrumb.label}</span>
            </Group>}
          </div>
          ))}
        </Group>}
        />

        <div className=" mobile-top-btn-section btn-container utility-bility-button">
          <ColorButton text={lang.utility.text_go_back} className={`gray-btn`} buttonConfig={{ disabled: true }} />
          <ColorButton
            onClick={() => {
              // this.moveForward();
              if (
                // (!this.props.isEdit && this.state.heatingSwitch && this.props.calculated) ||
                // (!this.props.isEdit && !this.state.heatingSwitch) ||
                // (this.props.isEdit && this.state.heatingSwitch)
                true
              ) {
                this.props.goNext();
                this.props.history.push(`/utility-billing/utility?currentBillingId=${this.props.currentDataId}&ub-step=utility-cost`);
              } else
                Autoload.DataHandlerGeneral.GeneralErrorMessage(true, lang.newKeys.text_error_calculate_heating_cost);
            }}
            text={lang.utility.text_next_utility_bills}
            className="purple-btn mr-1"
          />
        </div>

        <Divider className="utilitiy--divider" />

        <div className="cost-allocation-section">
          <div className="tooltips ub-heating-cost-distribution-headline">
            {/* text_heating_cost_allocation: ‘Heizkosten verteilen’, */}
            <h5 className="utility-cost-allocation">{`${lang.utility.text_heating_cost_allocation} `}</h5>
          </div>
          {/* text_heating_cost_allocation_desc: 'Hier ermitteln Sie die Heizkosten und legen diese um' */}
          <p className="utility-cost-allocation-subheading">Hier können Sie Heizkosten abrechnen.<span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', cursor: 'pointer'}} onClick={()=> { 
                  if (!skeletonLoading) {
                    this.props.goNext();
                    this.props.history.push(`/utility-billing/utility?currentBillingId=${this.props.currentDataId}&ub-step=utility-cost`, { isEdit: true });
                  }
                }}>Klicken Sie auf Weiter</span>, um weitere Nebenkosten und extern erstellte Heizkosten abzurechnen.</p>
          {
          // currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' ?
          <Box sx={{ height: '100vh', position: 'relative', marginTop: currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' && '40px'}}>
              {currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' &&
              <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <img src={PaidLockIcon} style={{zIndex: 50, position: 'relative', width: 56, marginTop: '-25px'}} />
              </div>}
            <Stack style={{zIndex: 1}}>
          <Row>
            <Col md={6} sm={12} style={{marginBottom: '7px'}}>
              <h5 className="heating-cost-allocation">{lang.newKeys.text_heating_cost}</h5>
              <div style={{display: 'flex', marginBottom: '2px'}}>
              {skeletonLoading ? 
              <Skeleton visible={skeletonLoading} style={{width: '250px', height: '20px', zIndex: '1'}}/>
              :
              <SwitchContainer
                className="heating-cost-switch"
                textClassName={`tooltips ${this.state.heatingSwitch ? 'ub-heating-cost-switch-active' : 'ub-heating-cost-switch-deactive'}`}
                onChange={this.handleHeatingSwitchChange}
                checked={this.props.propertySelected.heatingType === 'decentralized' ? false : this.state.heatingSwitch}
                switchText={
                  'Heizkosten aktivieren'//this.state.heatingSwitch ? 'deaktivieren' : 'aktivieren'
                }
                disabled={this.props.propertySelected.heatingType === 'decentralized' || utilityData.isSecured === true}
              />}
              </div>
            </Col>
            <Col md={6} sm={12} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
              {ubData?.heatingCostsActivated && (propertySelected.heatingType && propertySelected.heatingType === 'central with warm water' ? ((utilityBillingFreshData && utilityBillingFreshData.hasCentralWarmWaterAmount) ? (utilityBillingFreshData ? (utilityBillingFreshData.energyAmountWarmWater !== 0 && utilityBillingFreshData.energyAmountWarmWater !== null) : (ubData?.energyAmountWarmWater !== 0 && ubData?.energyAmountWarmWater !== null)) : true) : true) && 
                    (utilityBillingFreshData ? (utilityBillingFreshData.heatingEnergyAmount !== 0 && utilityBillingFreshData.heatingEnergyAmount !== null) : (ubData?.heatingEnergyAmount !== 0 && ubData?.heatingEnergyAmount !== null)) &&
                    (utilityBillingFreshData ? (utilityBillingFreshData.totalHeatingCost !== null && utilityBillingFreshData.totalHeatingCost !== 0) : (ubData?.totalHeatingCost !== null && ubData?.totalHeatingCost !== 0)) &&
              <div className="calculation-status-div">
                {!this.state.heatingSwitch ? 
                skeletonLoading && 
                    <Skeleton visible={skeletonLoading} style={{width: '180px', height: '20px', marginRight: '10px', zIndex: '1'}}/> 
                  : 
                  (skeletonLoading ? 
                    <Skeleton visible={skeletonLoading} style={{width: '180px', height: '20px', marginRight: '10px', zIndex: '1'}}/>
                    :
                  <div className="calculation-status">
                    {propertySelected.heatingType === 'central with warm water' ?
                    ubData?.totalHeatingCost && ubData?.heatingEnergyAmount &&
                    ((utilityBillingFreshData && utilityBillingFreshData.hasCentralWarmWaterAmount) ? utilityBillingFreshData.energyAmountWarmWater : true) ? (
                      <div>
                        <img src={GreenTick} alt="status" />
                        <span className={`heating-cost-not-available calculated`}>
                          {lang.newKeys.text_heating_cost_available}
                        </span>
                      </div>
                    ) : (utilityBillingFreshData ? utilityBillingFreshData.energyAmountWarmWater : ubData?.energyAmountWarmWater) || 
                    (utilityBillingFreshData ? utilityBillingFreshData.heatingEnergyAmount : ubData?.heatingEnergyAmount) ||
                    (utilityBillingFreshData ? utilityBillingFreshData.totalHeatingCost : ubData?.totalHeatingCost) 
                    ?
                    (
                      <div>
                        <img src={RedTick} alt="status" />
                        <span className={`heating-cost-not-available`}>
                          {lang.newKeys.text_heating_cost_available_incomplete}
                        </span>
                      </div>
                    )
                    :
                    (
                      <div>
                        <img src={RedTick} alt="status" />
                        <span className={`heating-cost-not-available`}>
                          {lang.newKeys.text_heating_cost_not_available}
                        </span>
                      </div>
                    )
                    :
                    (propertySelected.heatingType !== 'decentralized' && (ubData?.totalHeatingCost && ubData?.heatingEnergyAmount ? 
                      (
                        <div>
                          <img src={GreenTick} alt="status" />
                          <span className={`heating-cost-not-available calculated`}>
                            {lang.newKeys.text_heating_cost_available}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <img src={RedTick} alt="status" />
                          <span className={`heating-cost-not-available`}>
                            {lang.newKeys.text_heating_cost_not_available}
                          </span>
                        </div>
                      ))
                    )
                  }
                  </div>
                  
                )}
                {this.state.heatingSwitch === true ? (
                  <>
                    <div style={{marginTop: '4px'}}>
                      {skeletonLoading ? 
                      <Skeleton visible={skeletonLoading} style={{width: '163px', height: '37px', zIndex: '1'}}/>
                      :
                      <ColorButton
                        text={'Heizkosten bearbeiten'//this.props.calculated ? lang.general.text_edit : 'Heizkosten bearbeiten'
                        }
                        className='gray-btn'
                        onClick={() => this.openHeatingCostModal()}
                        disabled={this.props.propertySelected.heatingType === 'decentralized'}
                      />}
                    </div>
                  </>
                )
                :
                skeletonLoading ?
                <Skeleton visible={skeletonLoading} style={{width: '163px', height: '37px', zIndex: '1'}}/>
                : null
              }
              </div>}
            </Col>
          </Row>
          {this.props.propertySelected.heatingType === 'decentralized' ? 
            <EmptyStateContainer
            heading={
              'Mit der von Ihnen gewählten Heizungsart können keine Heizkosten verrechnet werden.'
            }
            subheading=""
            icon={tap}
            showBtn={false}
            /> :
          this.state.heatingSwitch === true ? (
            <div className={isMobile ? 'mobile-utiltiy-cost' : ''} //style={{height: (isTablet && dropdownOpen) ? '80vh' : dropdownOpen ? '90vh' : 'auto'}}
            >
              {this.state.skeletonLoading ? 
              <>
                <Skeleton visible={this.state.skeletonLoading} style={{width: '100%', height: '16px', marginTop: '20px', marginBottom: '8px', zIndex: '1'}}/>
                {[...Array(5)].map((e, i) => <Skeleton visible={this.state.skeletonLoading} style={{height: '75px', marginBottom: '8px', zIndex: '1'}}/>)}
              </>
              :
              ubData?.heatingCostsActivated && ((propertySelected.heatingType && propertySelected.heatingType === 'central with warm water') ? ((utilityBillingFreshData && utilityBillingFreshData.hasCentralWarmWaterAmount) ? (utilityBillingFreshData ? utilityBillingFreshData.energyAmountWarmWater : ubData?.energyAmountWarmWater) : true) : true) &&
              (utilityBillingFreshData ? utilityBillingFreshData.heatingEnergyAmount : ubData?.heatingEnergyAmount) &&
              (utilityBillingFreshData ? utilityBillingFreshData.totalHeatingCost : ubData?.totalHeatingCost) ? 
              <TableContainer
              className='ub-table-container'
              style={{height: '100vh'}}
              >
              <Table //className={`table borderless ${className} mb-0`}
              style={{borderCollapse: 'separate', borderSpacing: '0px 8px'}}
              >
                {!isMobile ? 
                <TableHead>{this.getTableLabels()}</TableHead> : null}
                <TableBody //className={isMobile ? 'mobile-cost-table-rows' : 'heating-cost-page'}
                >
                  {this.getTableData()}
                </TableBody>
              </Table>
              </TableContainer>
              :
              <>
              <EmptyStateContainer
              heading={
                'Heizkostenabrechnung ist aktiviert'
              }
              subheading="Klicken Sie auf Heizkosten berechnen, um die notwendigen Informationen zur Heizkostenabrechnung bereitzustellen."
              icon={tap}
              showBtn={true}
              btnText={'Heizkosten berechnen'}
              btnHandler={() => {
                if((propertySelected.heatingType === null || propertySelected.heatingType === '') || (propertySelected.energyFuelType ===  null || propertySelected.energyFuelType ===  '') || (propertySelected.heatingTemperature === null || propertySelected.heatingTemperature === '') || (propertySelected.consumptionValue === null || propertySelected.consumptionValue === '') || (propertySelected.consumptionShare === null || propertySelected.consumptionShare === '')){
                  this.setState({openPropertyHeatingModal: true});
                }else{
                  if(this.state.heatingCostPositions && this.state.heatingCostPositions.length !== 0 ){
                    // this.props.setRunUpdateHeatingCostSplit(true);
                    // this.updateHeatingCostSplit(true, false, false, true)
                    this.openHeatingCostModal();
                  }else{
                    showNotification({message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.', icon: <img src={RedCross} alt='red-cross'/>});
                  }
                } 
              
              }}
              />
              <div style={{textAlign: 'center', marginTop: '12px'}}>
                <img src={RedTick} alt="status" />
                <span className={`heating-cost-not-available`}>
                  {
                    'Die Angaben sind noch unvollständig.'//lang.newKeys.text_heating_cost_not_available
                  }
                </span>
              </div>
              </>
              }
            </div>
          ) : (
            this.state.skeletonLoading ? 
              <>
                <Skeleton visible={this.state.skeletonLoading} style={{width: '100%', height: '16px', marginTop: '20px', marginBottom: '-8px', zIndex: '1'}}/>
                {[...Array(5)].map((e, i) => <Skeleton visible={this.state.skeletonLoading} style={{height: '75px', marginBottom: '-8px', zIndex: '1'}}/>)}
              </>
              :<EmptyStateContainer
              // heading={lang.utility.text_no_utility_billing}
              heading={
                this.props.propertySelected.heatingType === 'decentralized' ? 'Mit der von Ihnen gewählten Heizungsart können keine Heizkosten verrechnet werden.' : 'Heizkostenabrechnung ist deaktiviert'//'Heizkosten werden nicht in die Abrechnung einbezogen.'
              }
              subheading={this.props.propertySelected.heatingType !== 'decentralized' && "Heizkosten werden nicht in die Abrechnung einbezogen. Aktivieren Sie den Heizkostenschalter, um Heizkosten abzurechnen."}
              icon={tap}
              showBtn={false}
            />
          )}
            </Stack>
            {!this.state.skeletonLoading && currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' &&
            <Stack align={'center'} style={{ justifyContent: 'center', zIndex: 50, position: 'relative'}}>
              <Stack spacing={0} style={{marginTop: this.state.heatingSwitch && ubData?.heatingCostsActivated && ((propertySelected.heatingType && propertySelected.heatingType === 'central with warm water') ? ((utilityBillingFreshData && utilityBillingFreshData.hasCentralWarmWaterAmount) ? (utilityBillingFreshData ? utilityBillingFreshData.energyAmountWarmWater : ubData?.energyAmountWarmWater) : true) : true) &&
              (utilityBillingFreshData ? utilityBillingFreshData.heatingEnergyAmount : ubData?.heatingEnergyAmount) &&
              (utilityBillingFreshData ? utilityBillingFreshData.totalHeatingCost : ubData?.totalHeatingCost) ? '-1035px' : '-150px'}}>
                <span style={{color: '#0E0E1D', fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>Heizkosten einfach selbst abrechnen.</span>
                <span style={{color: '#5555FF', fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>Mit hellohousing Premium.</span>
              </Stack>
              <Group>
                <div>
                  <ColorButton
                  text={currentSubscription ? (currentSubscription?.subscriptionName === 'Starter' ? (accountData.isPremiumPlanTested === false ? 'Jetzt freischalten' : 'Freischalten') : currentSubscription?.subscriptionName === 'Premium' ? (accountData.isProfessionalPlanTested === false ? 'Jetzt freischalten' : 'Freischalten') : 'Freischalten') : 'Freischalten'}
                  className='purple-btn'
                  onClick={()=> this.props.history.push('/profile/yourSubscription', {newSubscription: true})}
                  />
                </div>
              </Group>
              <Group style={{width: '460px'}}>
                <span style={{color: '#615C7E', fontSize: '12px', textAlign: 'center'}}>Erstellen Sie mit wenigen Klicks die jährliche Heizkostenabrechnung entsprechend den Vorgaben der Heizkostenverordnung (HKVO) - hellohousing berechnet die Heizkosten und übernimmt diese in die Betriebskostenabrechnung.</span>
              </Group>
              <Stack spacing={2}>
                <Group position='center'>
                  <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', cursor: 'pointer'}} onClick={()=> window.Intercom('showArticle', '7937190')}>Heizkostenabrechnung kennenlernen</span>
                </Group>
                <Group style={{width: '460px'}} position={'center'}>
                  <span style={{color: '#615C7E', fontSize: '12px', textAlign: 'center'}}>oder</span>
                </Group>
                <Group position='center'>
                  <span style={{color: '#5555FF', marginLeft: '5px', fontSize: '14px', cursor: 'pointer'}} onClick={()=> { 
                    if (!skeletonLoading) {
                      this.props.goNext();
                      this.props.history.push(`/utility-billing/utility?currentBillingId=${this.props.currentDataId}&ub-step=utility-cost`, { isEdit: true });
                    }
                  }}>Ohne Heizkostenabrechnung fortfahren</span>
                </Group>
              </Stack>
            </Stack>}
            {currentSubscription?.subscriptionKey.split('_')[0] === 'STARTER' && 
            <Overlay opacity={0.5} blur={4} zIndex={5} color={'#F7F7FA'} style={{borderTop: '1px solid #9DA3AE'}}/>}
          </Box>
          // :
          // null
          // <>
          // <Row>
          //   <Col md={6} sm={12} style={{marginBottom: '7px'}}>
          //     <h5 className="heating-cost-allocation">{lang.newKeys.text_heating_cost}</h5>
          //     <div style={{display: 'flex', marginBottom: '2px'}}>
          //     {skeletonLoading ? 
          //     <Skeleton visible={skeletonLoading} style={{width: '250px', height: '20px'}}/>
          //     :
          //     <SwitchContainer
          //       className="heating-cost-switch"
          //       textClassName={`tooltips ${this.state.heatingSwitch ? 'ub-heating-cost-switch-active' : 'ub-heating-cost-switch-deactive'}`}
          //       onChange={this.handleHeatingSwitchChange}
          //       checked={this.props.propertySelected.heatingType === 'decentralized' ? false : this.state.heatingSwitch}
          //       switchText={
          //         'Heizkosten aktivieren'//this.state.heatingSwitch ? 'deaktivieren' : 'aktivieren'
          //       }
          //       disabled={this.props.propertySelected.heatingType === 'decentralized' || utilityData.isSecured === true}
          //     />}
          //     </div>
          //   </Col>
          //   <Col md={6} sm={12} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
          //     {ubData.heatingCostsActivated && (propertySelected.heatingType && propertySelected.heatingType === 'central with warm water' ? ((utilityBillingFreshData && utilityBillingFreshData.hasCentralWarmWaterAmount) ? (utilityBillingFreshData ? utilityBillingFreshData.energyAmountWarmWater : ubData?.energyAmountWarmWater) : true) : true) && 
          //           (utilityBillingFreshData ? utilityBillingFreshData.heatingEnergyAmount : ubData?.heatingEnergyAmount) &&
          //           (utilityBillingFreshData ? (utilityBillingFreshData.totalHeatingCost !== null && utilityBillingFreshData.totalHeatingCost !== 0) : (ubData?.totalHeatingCost !== null && ubData?.totalHeatingCost !== 0)) &&
          //     <div className="calculation-status-div">
          //       {!this.state.heatingSwitch ? 
          //       skeletonLoading && 
          //           <Skeleton visible={skeletonLoading} style={{width: '180px', height: '20px', marginRight: '10px'}}/> 
          //         : 
          //         (skeletonLoading ? 
          //           <Skeleton visible={skeletonLoading} style={{width: '180px', height: '20px', marginRight: '10px'}}/>
          //           :
          //         <div className="calculation-status">
          //           {propertySelected.heatingType === 'central with warm water' ?
          //           ubData?.totalHeatingCost && ubData?.heatingEnergyAmount &&
          //           ((utilityBillingFreshData && utilityBillingFreshData.hasCentralWarmWaterAmount) ? utilityBillingFreshData.energyAmountWarmWater : true) ? (
          //             <div>
          //               <img src={GreenTick} alt="status" />
          //               <span className={`heating-cost-not-available calculated`}>
          //                 {lang.newKeys.text_heating_cost_available}
          //               </span>
          //             </div>
          //           ) : (utilityBillingFreshData ? utilityBillingFreshData.energyAmountWarmWater : ubData?.energyAmountWarmWater) || 
          //           (utilityBillingFreshData ? utilityBillingFreshData.heatingEnergyAmount : ubData?.heatingEnergyAmount) ||
          //           (utilityBillingFreshData ? utilityBillingFreshData.totalHeatingCost : ubData?.totalHeatingCost) 
          //           ?
          //           (
          //             <div>
          //               <img src={RedTick} alt="status" />
          //               <span className={`heating-cost-not-available`}>
          //                 {lang.newKeys.text_heating_cost_available_incomplete}
          //               </span>
          //             </div>
          //           )
          //           :
          //           (
          //             <div>
          //               <img src={RedTick} alt="status" />
          //               <span className={`heating-cost-not-available`}>
          //                 {lang.newKeys.text_heating_cost_not_available}
          //               </span>
          //             </div>
          //           )
          //           :
          //           (propertySelected.heatingType !== 'decentralized' && (ubData?.totalHeatingCost && ubData?.heatingEnergyAmount ? 
          //             (
          //               <div>
          //                 <img src={GreenTick} alt="status" />
          //                 <span className={`heating-cost-not-available calculated`}>
          //                   {lang.newKeys.text_heating_cost_available}
          //                 </span>
          //               </div>
          //             ) : (
          //               <div>
          //                 <img src={RedTick} alt="status" />
          //                 <span className={`heating-cost-not-available`}>
          //                   {lang.newKeys.text_heating_cost_not_available}
          //                 </span>
          //               </div>
          //             ))
          //           )
          //         }
          //           {/* <img src={this.props.calculated ? GreenTick : RedTick} alt="status" />
          //           <span className={`heating-cost-not-available ${this.props.calculated && 'calculated'} `}>
          //             {this.props.calculated
          //               ? lang.newKeys.text_heating_cost_available
          //               : lang.newKeys.text_heating_cost_not_available}
          //           </span> */}
          //         </div>
                  
          //       )}
          //       {this.state.heatingSwitch === true ? (
          //         <>
          //           {/* <div className="calculation-status">
          //             <img src={this.props.calculated ? GreenTick : RedTick} alt="status" />
          //             <span className={`heating-cost-not-available ${this.props.calculated && 'calculated'} `}>
          //               {this.props.calculated
          //                 ? lang.newKeys.text_heating_cost_avaialable
          //                 : lang.newKeys.text_heating_cost_not_avaialable}
          //             </span>
          //           </div> */}
          //           <div style={{marginTop: '4px'}}>
          //             {skeletonLoading ? 
          //             <Skeleton visible={skeletonLoading} style={{width: '163px', height: '37px'}}/>
          //             :
          //             <ColorButton
          //               text={'Heizkosten bearbeiten'//this.props.calculated ? lang.general.text_edit : 'Heizkosten bearbeiten'
          //               }
          //               className='gray-btn'
          //               onClick={() => this.openHeatingCostModal()}
          //               disabled={this.props.propertySelected.heatingType === 'decentralized'}
          //             />}
          //           </div>
          //         </>
          //       )
          //       :
          //       skeletonLoading &&
          //       <Skeleton visible={skeletonLoading} style={{width: '163px', height: '37px'}}/>
          //     }
          //     </div>}
          //   </Col>
          // </Row>
          // {this.props.propertySelected.heatingType === 'decentralized' ? 
          //   <EmptyStateContainer
          //   heading={
          //     'Mit der von Ihnen gewählten Heizungsart können keine Heizkosten verrechnet werden.'
          //   }
          //   subheading=""
          //   icon={tap}
          //   showBtn={false}
          //   /> :
          // this.state.heatingSwitch === true ? (
          //   <div className={isMobile ? 'mobile-utiltiy-cost' : ''} //style={{height: (isTablet && dropdownOpen) ? '80vh' : dropdownOpen ? '90vh' : 'auto'}}
          //   >
          //     {this.state.skeletonLoading ? 
          //     <>
          //       <Skeleton visible={this.state.skeletonLoading} style={{width: '100%', height: '16px', marginTop: '20px', marginBottom: '8px'}}/>
          //       {[...Array(5)].map((e, i) => <Skeleton visible={this.state.skeletonLoading} style={{height: '75px', marginBottom: '8px'}}/>)}
          //     </>
          //     :
          //     ubData.heatingCostsActivated && ((propertySelected.heatingType && propertySelected.heatingType === 'central with warm water') ? ((utilityBillingFreshData && utilityBillingFreshData.hasCentralWarmWaterAmount) ? (utilityBillingFreshData ? utilityBillingFreshData.energyAmountWarmWater : ubData?.energyAmountWarmWater) : true) : true) &&
          //     (utilityBillingFreshData ? utilityBillingFreshData.heatingEnergyAmount : ubData?.heatingEnergyAmount) &&
          //     (utilityBillingFreshData ? utilityBillingFreshData.totalHeatingCost : ubData?.totalHeatingCost) ? 
          //     <TableContainer
          //     className='ub-table-container'
          //     style={{height: '100vh'}}
          //     >
          //     <Table //className={`table borderless ${className} mb-0`}
          //     style={{borderCollapse: 'separate', borderSpacing: '0px 8px'}}
          //     >
          //       {!isMobile ? 
          //       <TableHead>{this.getTableLabels()}</TableHead> : null}
          //       <TableBody //className={isMobile ? 'mobile-cost-table-rows' : 'heating-cost-page'}
          //       >
          //         {this.getTableData()}
          //       </TableBody>
          //     </Table>
          //     </TableContainer>
          //     :
          //     <>
          //     <EmptyStateContainer
          //     heading={
          //       'Heizkostenabrechnung ist aktiviert'
          //     }
          //     subheading="Klicken Sie auf Heizkosten berechnen, um die notwendigen Informationen zur Heizkostenabrechnung bereitzustellen."
          //     icon={tap}
          //     showBtn={true}
          //     btnText={'Heizkosten berechnen'}
          //     btnHandler={() => ((propertySelected.heatingType === null || propertySelected.heatingType === '') || (propertySelected.energyFuelType ===  null || propertySelected.energyFuelType ===  '') || (propertySelected.heatingTemperature === null || propertySelected.heatingTemperature === '') || (propertySelected.consumptionValue === null || propertySelected.consumptionValue === '') || (propertySelected.consumptionShare === null || propertySelected.consumptionShare === '')) ? this.setState({openPropertyHeatingModal: true}) : this.state.heatingCostPositions && this.state.heatingCostPositions.length !== 0 ? this.openHeatingCostModal() : showNotification({message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.', icon: <img src={RedCross} alt='red-cross'/>})}
          //     />
          //     <div style={{textAlign: 'center', marginTop: '12px'}}>
          //       <img src={RedTick} alt="status" />
          //       <span className={`heating-cost-not-available`}>
          //         {
          //           'Die Angaben sind noch unvollständig.'//lang.newKeys.text_heating_cost_not_available
          //         }
          //       </span>
          //     </div>
          //     </>
          //     }
          //   </div>
          // ) : (
          //   this.state.skeletonLoading ? 
          //     <>
          //       <Skeleton visible={this.state.skeletonLoading} style={{width: '100%', height: '16px', marginTop: '20px', marginBottom: '8px'}}/>
          //       {[...Array(5)].map((e, i) => <Skeleton visible={this.state.skeletonLoading} style={{height: '75px', marginBottom: '8px'}}/>)}
          //     </>
          //     :<EmptyStateContainer
          //     // heading={lang.utility.text_no_utility_billing}
          //     heading={
          //       this.props.propertySelected.heatingType === 'decentralized' ? 'Mit der von Ihnen gewählten Heizungsart können keine Heizkosten verrechnet werden.' : 'Heizkostenabrechnung ist deaktiviert'//'Heizkosten werden nicht in die Abrechnung einbezogen.'
          //     }
          //     subheading={this.props.propertySelected.heatingType !== 'decentralized' && "Heizkosten werden nicht in die Abrechnung einbezogen. Aktivieren Sie den Heizkostenschalter, um Heizkosten abzurechnen."}
          //     icon={tap}
          //     showBtn={false}
          //   />
          // )}
          // </>
          }
          {this.state.heatingCostModal && (
            <HeatingCostModal
              history={this.props.history}
              className="heating-cost-modal"
              isMobile={isMobile}
              isTablet={isTablet}
              open={this.state.heatingCostModal}
              toggle={this.closeHeatingCostModal}
              cpId={this.getCpId()}
              callGetDataApi={this._getHeatingCostPositions}
              updateCalculationStatus={(status) => this.props.updateCalculationStatus(status)}
              toggleReceipt={(id) => this.toggleAddReceiptModal(id)}
              receiptsArr={this.state.receipts}
              utilityData={this.state.utilityData}
              timeFrame={this.state.utilityData.date}
              billingPeriodId={this.state.billingPeriodId}
              utilityBillingFreshData={utilityBillingFreshData}
              callApis={()=>GlobalServices._getReceiptsByProperty(this, '_handleReceiptsResponse', this.props.propertySelected)}
              toggleViewDetailsModal={this.toggleViewDetailsModal}
              editReceiptOpen={this.state.editReceiptOpen}
              callUpdate={(updateHeatingCostSplit)=>{
                this.setState({allowUpdateHeatingCostSplit: updateHeatingCostSplit})
                if(runUpdateTenantOccupations){
                  this.updateTenantOccupations();
                }else{
                  this.setState({skeletonLoading: true});
                  this.updateConsumptions();
                }
              }}
            />
          )}
          <ShowModal
            onEditClick={this.handleEditClick}
            onExpansionClick={this.toggleImageModal}
            record={currentRecord}
            className="show-receipt-modal"
            open={showModal}
            closeShow={this.closeShow}
          />
          <ImageModal
            img={currentRecord && currentRecord.file_name !== '' ? currentRecord.file_name : Receipt}
            className="expandImageModal"
            open={imageOpen}
            toggle={this.toggleImageModal}
          />
          <AddPropertyHeatingModal
          className="add_proprty_modal"
          edit={false}
          open={openPropertyHeatingModal}
          toggle={() => {
            this.setState({openPropertyHeatingModal: false});
          }}
          openHeatingCostModal={()=> {
            console.log('openHeatingCostModal: ', this.props.currentDataId);
            this.updateHeatingCostSplit(true, false, true);
            this.setState({openPropertyHeatingModal: false});
            //showNotification({message: 'No cost positions exist. Kindly delete and recreate UtilityBilling.', icon: <img src={RedCross} alt='red-cross'/>})
          }}
          // propertyType={this.state.selectedPropertyType}
          />
        </div>
        <ToastContainer
          position="top-center"
          autoClose={false}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        {editReceiptOpen && (
            <ReceiptDetailModal
              history={this.props.history}
              record={currentRecord}
              parent={this}
              className="add-edit-receipt-modal"
              edit={true}
              costAllocation={true}
              open={editReceiptOpen}
              toggle={() => this.closeEditReceipt()}
              refreshData={()=> this.getRecord(currentRecord.id, false)}
            />
          )}

        {opentotalOccupantsCountNullAlert &&
        <AlertModal
        open={opentotalOccupantsCountNullAlert}
        heading={'Gesamtpersonenanzahl angeben'}
        headingClassName={'tooltips property-cut-off-date-alert'}
        text={<span>Um den Verteilerschlüssel <span style={{fontWeight: '500'}}>Personenanzahl</span> zu nutzen, müssen Sie zunächst die Personenanzahl des Gesamthauses angeben.</span>}
        cancel
        rightButtonText={'Anzahl angeben'}
        toggle={()=> this.setState({opentotalOccupantsCountNullAlert: false})}
        onClick={()=> this.props.history.push('/property/generalInformation')}
        onCancel={()=>this.setState({opentotalOccupantsCountNullAlert: false})}
        />}
        {openFreezeModal &&
        <AlertModal
        heading={freezeAlertHeading}
        open={openFreezeModal}
        dataSecured={utilityData.isSecured}
        cancel
        onCancel={()=> this.setState({openFreezeModal: false})}
        text={
          <span style={{fontSize: '14px'}}>
            {freezeAlertMessage}
          </span>
        }         
        onClick={(e) => {
          e.preventDefault();
          this.setState({openFreezeModal: false});
          this.lockUtilityBilling(this.props.currentDataId, utilityData.isSecured);
        }}
        toggle={() => {
          this.setState({openFreezeModal: false});
        }}
        />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    utilityBillingData: state.UtilityBillingReducer.utilityBillingData,
    // currentDataId: state.UtilityBillingReducer.currentBillingId,
    propertySelected: state.generalReducer.property,
    filter: state.UtilityBillingReducer.HeatingAllocationFilter,
    currentSubscription: state.generalReducer.currentSubscription,
    accountData: state.generalReducer.accountData,
    runUpdateTenantOccupations: state.generalReducer.runUpdateTenantOccupations,
    runUpdateReceiptAssignment: state.generalReducer.runUpdateReceiptAssignment,
    runUpdateConsumptions: state.generalReducer.runUpdateConsumptions,
    runUpdateHeatingCostSplit: state.generalReducer.runUpdateHeatingCostSplit,
  };
};
const actions = {
  setCostPositions,
  addCostPosition,
  setHeatingAllocation,
  setUtilityBillingData,
  setRunUpdateReceiptAssignment,
  setRunUpdateTenantOccupations,
  setRunUpdateConsumptions,
  setRunUpdateHeatingCostSplit
};

export default connect(mapStateToProps, actions)(HeatingCostAllocation);
