import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { hideNotification, showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import { Checkbox, Group, Loader, Stack, TextInput, Tooltip as MantineTooltip, Menu } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import Bank from '../../../../../assets/images/module/general/Bank.svg';
import { NumericFormat } from 'react-number-format';
import Autoload from '../../../../Autoload';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { connect } from 'react-redux';
import TruncatedTextHandler from '../../../../../components/TruncatedTextHandler';
import GlobalServices from '../../../../helpers/services/GlobalServices';
import dayjs from 'dayjs';
import SearchContainer from '../SearchContainer/SearchContainer';

const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

export class AllTransactionMappingsModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    tenants: [],
    mappingLoader: false,
    isEarlyPaymentEnabled: false,
    mappings: [],
    costTypes: [],
    stopToggle: false,
    openCostTypeMenu: false,
    openTaxCostTypeMenu: false,
    openTaxRateMenu: false,
    customTaxRate: false,
    mappingLoader: false,
    openTenantMenu: false,
    openIsEarlyPaymentMenu: false,
    openAutoAssignReceiptTenantTransaction: false,
    currentBillingPeriod: null,
    openDeleteAllTransactionMappingConfirmationModal: false,
    taxCostTypes: [
      {label: 'Schuldzinsen'},
      {label: 'Geldbeschaffungskosten'},
      {label: 'Erhaltungsaufwendungen'},
      {label: 'Laufende Betriebskosten'},
      {label: 'Verwaltungskosten'},
      {label: 'Sonstige Kosten'},
  
      {label: 'Erstattete Vorauszahlungen'},
      {label: 'Erhaltene Nachzahlungen'},
      {label: 'Vereinnahmte Kautionen / Mieten aus Vorjahren'},
      {label: 'Vom Finanzamt erstattete Umsatzsteuer'},
      {label: 'Öffentliche Zuschüsse'},
      {label: 'AfA Gebäude'},
      {label: 'AfA bewegliche Wirtschaftsgüter'},
      {label: 'An Finanzamt gezahlte Umsatzsteuer'},
      {label: 'Renten, dauernde Lasten'},
    ],
    search: null,
    loader: false
  };
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    const {rentalUnits} = this.props;
    let tenants = [];
    rentalUnits.map((rentalUnit)=> rentalUnit.tenantsData.map((tenant)=> {
        if(tenant.vacant === false){
            let noOfMonthBetweenMoveInMoveOut= this.getMonthsBetween(tenant.in, tenant.out.length > 0 ? tenant.out : null);
            tenants.push({...tenant, rentalUnitName: rentalUnit.name, value: tenant.id, label: `${rentalUnit.name}: ${tenant.name} (${tenant.in} - ${tenant.out ? tenant.out : 'aktuell'})`, noOfMonthBetweenMoveInMoveOut});
        }
    }));

    this.setState({tenants, loader: true},()=> {
      this.getAllTransactionMappings();
      this.getUtilityBillingCostTypes();
      this.getCurrentBillingPeriod();
    });
  }

  getUtilityBillingCostTypes=()=>{
    GlobalServices._getCostTypeByProperty(this, 'handleGetUtilityBillingCostTypes', this.props.propertySelected);
  }

  handleGetUtilityBillingCostTypes(response){
    if(response.status && response.statuscode === 200){
      this.setState({costTypes: response.result.map((costType)=> ({...costType, label: costType.costTypeName, value: costType.id}))});
    }
  }

  getCurrentBillingPeriod=()=>{
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.property_api_base_url +
      'currentBillingPeriod' +
      '?propertyId=' +
      this.props.propertySelected.id +
      '&offset=0&fetch=10',
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      "handleGetCurrentBillingPeriodResponse",
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetCurrentBillingPeriodResponse(response){
    if(response.status && response.statuscode === 200){
      this.setState({currentBillingPeriod: response.result});
    }
  }

  getAllTransactionMappings=()=>{
    const {propertySelected} = this.props;
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + `TransactionMappings/Property/${propertySelected.id}`,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleGetAllTransactionMappings',
      null,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  }

  handleGetAllTransactionMappings(response){
    if(response.status && response.statuscode === 200){
      this.setState({mappings: response.result});
    }
    this.setState({loader: false});
  }

  getMonthsBetween=(dateA, dateB)=> {
    const startDate = dayjs(dateA, 'DD.MM.YYYY');
    const endDate = dateB ? dayjs(dateB, 'DD.MM.YYYY') : dayjs();
    console.log('endDate: ', endDate, dateB);
    const months = [];
    let current = endDate;
    while (current.isAfter(startDate) || current.isSame(startDate, 'month')) {
      months.push({month: current, label: current.format('MMMM YYYY'), value: current.format('MMMM YYYY')}); // Customize the format as needed
      current = current.subtract(1, 'month');
    }
    return months;
  }

  renderHeader=()=>{
    return (
      <TableHead>
        <TableRow>
          <TableCell classes={{stickyHeader: 'bank-transaction-sticky-header', root: 'bank-transaction-table-cell-root'}}>IBAN</TableCell>
          <TableCell classes={{stickyHeader: 'bank-transaction-sticky-header', root: 'bank-transaction-table-cell-root'}} style={{whiteSpace: 'nowrap'}}>Sender / Empfänger</TableCell>
          <TableCell classes={{stickyHeader: 'bank-transaction-sticky-header', root: 'bank-transaction-table-cell-root'}}>Art der Zahlung</TableCell>
          <TableCell classes={{stickyHeader: 'bank-transaction-sticky-header', root: 'bank-transaction-table-cell-root'}} width={'70%'}>Regeln</TableCell>
        </TableRow>
      </TableHead>
    )
  }

  handleMappingChange=(mappingId, field, selectedValue)=>{
    let {mappings} = this.state;
    mappings = mappings.map((mapping)=> {
      if(mapping.id === mappingId){
        mapping[field]= selectedValue;
      }
      return mapping;
    });
    this.setState({mappings}, ()=> this.putTransactionMapping(mappingId));
  }

  putTransactionMapping=(mappingId)=>{
    console.log('putTransactionMapping: ', mappingId);
    const {mappings} = this.state;
    const payload = mappings.find((mapping)=> mapping.id === mappingId);
    if(payload !== undefined){
      this.setState({mappingLoader: mappingId});
      hideNotification('putTrasactionMappingSuccess');
      hideNotification('putTrasactionMappingError');
      Autoload.HttpRequestAxios.HttpRequest(
        Autoload.Setting.finance_api_base_url + 'TransactionMappings/'+ mappingId,
        'PUT',
        payload,
        null,
        false,
        true,
        false,
        this,
        'handlePutTransactionMapping',
        null,
        false,
        '',
        {
          'Content-Type': 'application/json',
          Authorization: Autoload.Utils.getAuthToken(),
        },
        true
      );
    }
  }

  handlePutTransactionMapping(response){
    if(response.status && response.statuscode === 200){
      showNotification({
        message: 'Regel erfolgreich aktualisiert.',
        icon: <img src={GreenTick} alt='green-tick'/>,
        id: 'putTrasactionMappingSuccess'
      });
      //this.getAllTransactionMappings();
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'putTrasactionMappingError'
      });
    }

    this.setState({mappingLoader: false});
  }

  removeMapping=(mappingId)=>{
    this.deleteTransactionMapping(mappingId);
  }

  deleteTransactionMapping=(mappingId, eol=null)=>{
    const {selectedTransaction} = this.props;
    this.setState({mappingLoader: mappingId});
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.finance_api_base_url + "TransactionMappings/" + mappingId,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteTransactionMapping',
      {eol, mappingId},
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      },
      true
    );
  }

  handleDeleteTransactionMapping(response){
    if(response.status && response.statuscode === 200){
      showNotification({
        message: 'Regel erfolgreich gelöscht.',
        icon: <img src={RedTick} alt='red-tick'/>,
        id: 'DeleteTrasactionMapping'
      });
      let {mappings} = this.state;
      mappings = mappings.filter((mapping)=> mapping.id !== response.other.mappingId);
      this.setState({mappings});
    }else{
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-cross'/>,
        id: 'DeleteTrasactionMappingError'
      });
      this.setState({mappingLoader: false});
    }

    if(response.other.eol){
      this.props.toggle();
    }
  }

  renderExpenditureMapping=(mapping, hideDeleteButton=false)=>{
    const {costTypes, openCostTypeMenu, taxCostTypes, openTaxCostTypeMenu, openTaxRateMenu, customTaxRate, mappingLoader, anchorEl} = this.state;

    console.log('costTypes: ', costTypes);
    return (
      <Group noWrap position='apart' style={{width: '100%'}} className='tooltips transaction-mapping-item'>
      <div>
        <span className='transaction-mapping-receipts-text'>
          <span style={{fontWeight: '500'}}>Beleg erzeugen</span> (&nbsp;
            <Menu 
              style={{maxHeight: '150px'}}
              styles={{
                body: {
                  overflowY: 'scroll'
                }
              }}
              withinPortal={true} 
              zIndex={99999} 
              opened={openCostTypeMenu === mapping.id} 
              onOpen={()=> hideDeleteButton === false && this.setState({openCostTypeMenu: openCostTypeMenu === false ? mapping.id : false, stopToggle: openCostTypeMenu === false ? true : false})} 
              onClose={()=> this.setState({openCostTypeMenu: false, stopToggle: false, anchorEl: null})}
              control={
              <div>
                <TruncatedTextHandler 
                truncate={false} 
                removePointerCursor={hideDeleteButton}
                alwaysEnableTooltip={true}
                maxWidth={120} 
                alternateTooltipText={costTypes.find((costType)=> costType.id === mapping.utilityBillingCostTypeId) === undefined ? 'keine Angabe' : `Betriebskostenart: ${costTypes.find((costType)=> costType.id === mapping.utilityBillingCostTypeId)?.costTypeName}`} 
                className={costTypes.find((costType)=> costType.id === mapping.utilityBillingCostTypeId)?.costTypeName !== undefined ? 'transaction-mapping-capsules-receipts' : 'transaction-mapping-capsules-empty'} 
                text={costTypes.find((costType)=> costType.id === mapping.utilityBillingCostTypeId)?.costTypeName !== undefined ? costTypes.find((costType)=> costType.id === mapping.utilityBillingCostTypeId)?.costTypeName : 'Betriebskostenart'}/>
              </div>}>
              <Menu.Item style={{background: mapping.utilityBillingCostTypeId === null && '#e6e6ff', fontSize: '12px', fontStyle: 'italic'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'utilityBillingCostTypeId', null)}>keine Angabe</Menu.Item>
              {costTypes.map((costType)=> <Menu.Item style={{background: costType.value === mapping.utilityBillingCostTypeId && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'utilityBillingCostTypeId', costType.id)}>{costType.label}</Menu.Item>)}
            </Menu>
            ,&nbsp;
            <Menu 
              style={{maxHeight: '150px'}}
              styles={{
                body: {
                  overflowY: 'scroll'
                }
              }}
              withinPortal={true} 
              zIndex={99999} 
              opened={openTaxCostTypeMenu === mapping.id} 
              onOpen={()=> hideDeleteButton === false && this.setState({openTaxCostTypeMenu: openTaxCostTypeMenu === false ? mapping.id : false, stopToggle: openTaxCostTypeMenu === false ? true : false})} 
              onClose={()=> this.setState({openTaxCostTypeMenu: false, stopToggle: false})}
              control={
              <div>
                <TruncatedTextHandler 
                truncate={false} 
                removePointerCursor={hideDeleteButton}
                alwaysEnableTooltip={true}
                maxWidth={120} 
                alternateTooltipText={mapping.taxCostType === null ? 'keine Angabe' : `Steuerliche Kostenart: ${mapping.taxCostType}`} 
                className={mapping.taxCostType ? 'transaction-mapping-capsules-receipts' : 'transaction-mapping-capsules-empty'} 
                text={mapping.taxCostType ? mapping.taxCostType : 'steuerliche Kostenart'}/> 
              </div>}>
              <Menu.Item style={{background: mapping.taxCostType === null && '#e6e6ff', fontSize: '12px', fontStyle: 'italic'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'taxCostType', null)}>keine Angabe</Menu.Item>
              {taxCostTypes.map((taxCostType)=> <Menu.Item style={{background: taxCostType.label === mapping.taxCostType && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'taxCostType', taxCostType.label)}>{taxCostType.label}</Menu.Item>)}
            </Menu>,&nbsp;
            {customTaxRate ? 
            <span>
              <NumericFormat 
              // classNames={{input: 'transaction-mapping-capsules-receipts'}} 
              className='transaction-mapping-capsules-receipts'
              style={{maxWidth: '44px'}}
              //styles={{rightSection: {display: 'none'}, input: {maxWidth: '60px', padding: '5px', maxHeight: '24px', minHeight: '24px', fontSize: '12px'}, root: {maxWidth: '60px', padding: 0}}} 
              value={mapping.taxRate}
              thousandSeparator="." 
              decimalSeparator=","
              suffix={'%'}
              placeholder='0%'
              decimalScale={0}
              onValueChange={(values, sourceInfo) => this.handleMappingChange(mapping.id, 'taxRate', values.floatValue)}
              //onChange={(val)=> this.handleMappingChange(mapping.id, 'taxRate', val)}
              />
            </span>
            :
            <Menu 
              style={{maxHeight: '150px'}}
              styles={{
                body: {
                  overflowY: 'scroll'
                }
              }}
              withinPortal={true} 
              zIndex={99999} 
              opened={openTaxRateMenu === mapping.id} 
              onOpen={()=> hideDeleteButton === false && this.setState({openTaxRateMenu: openTaxRateMenu === false ? mapping.id : false, stopToggle: openTaxRateMenu === false ? true : false})} 
              onClose={()=> this.setState({openTaxRateMenu: false, stopToggle: false})}
              control={
              <div>
                <TruncatedTextHandler 
                truncate={false} 
                removePointerCursor={hideDeleteButton}
                alwaysEnableTooltip={true}
                maxWidth={120} 
                alternateTooltipText={mapping.taxRate === null ? 'keine Angabe' : `MWst.-Satz: ${mapping.taxRate}`} 
                className={mapping.taxRate ? 'transaction-mapping-capsules-receipts' : 'transaction-mapping-capsules-empty'} 
                text={mapping.taxRate ? `${mapping.taxRate}%` : 'MWst.-Satz'}/> 
              </div>}>
                <Menu.Item style={{background: mapping.taxRate === null && '#e6e6ff', fontSize: '12px', fontStyle: 'italic'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'taxRate', null)}>keine Angabe</Menu.Item>
                <Menu.Item style={{background: mapping.taxRate === 19 && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'taxRate', 19)}>19%</Menu.Item>
                <Menu.Item style={{background: mapping.taxRate === 7 && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'taxRate', 7)}>7%</Menu.Item>
                <Menu.Item style={{fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.setState({customTaxRate: true})}>Andere</Menu.Item>
            </Menu>}
            &nbsp;)
        </span>
      </div>
      {hideDeleteButton === false &&
      (mappingLoader === mapping.id ?
      <div>
        <Loader size={'xs'}/>
      </div>
      :
      <div>
        <MantineTooltip zIndex={99999} label='Regel löschen'><button onClick={()=> this.removeMapping(mapping.id)} type="button" class="close delete-cost-position" aria-label="Close"><span aria-hidden="true">×</span></button></MantineTooltip>
      </div>)}
    </Group>
    )
  }

  renderRentMapping=(mapping, hideDeleteButton=false)=>{
    const {tenants, mappingLoader, openTenantMenu, openIsEarlyPaymentMenu} = this.state;
    return(
      <Group noWrap position='apart' style={{width: '100%'}}>
      <span className='transaction-mapping-receipts-text'>
        <span style={{fontWeight: '500'}}>Mietzahlung erfassen</span> (&nbsp;
        <Menu 
          style={{maxHeight: '150px'}}
          styles={{
            body: {
              overflowY: 'scroll'
            }
          }}
          withinPortal={true} 
          zIndex={99999} 
          opened={openTenantMenu === mapping.id} 
          onOpen={()=> hideDeleteButton === false && this.setState({openTenantMenu: openTenantMenu === false ? mapping.id : false, stopToggle: openTenantMenu === false ? true : false})} 
          onClose={()=> this.setState({openTenantMenu: false, stopToggle: false})}
          control={
          <div>
            <TruncatedTextHandler 
            truncate={false} 
            removePointerCursor={hideDeleteButton}
            alwaysEnableTooltip={true}
            maxWidth={140} 
            alternateTooltipText={tenants.find((tenant)=> tenant.id === mapping.tenantId) === undefined ? 'keine Angabe' : `Mieter: ${tenants.find((tenant)=> tenant.id === mapping.tenantId)?.label}`} 
            className={tenants.find((tenant)=> tenant.id === mapping.tenantId) !== undefined ? 'transaction-mapping-capsules-tenant' : 'transaction-mapping-capsules-empty'} 
            text={`${tenants.find((tenant)=> tenant.id === mapping.tenantId) !== undefined ? tenants.find((tenant)=> tenant.id === mapping.tenantId)?.label : 'Mieter'}`}/>
          </div>}>
          {/* <Menu.Item style={{background: mapping.tenantId === null && '#e6e6ff', fontSize: '12px', fontStyle: 'italic'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'tenantId', null)}>keine Angabe</Menu.Item> */}
          {tenants.map((tenant)=> <Menu.Item style={{background: tenant.value === mapping.tenantId && '#e6e6ff', fontSize: '12px', lineHeight: '15px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'tenantId', tenant.id)}>{tenant.label}</Menu.Item>)}
        </Menu>,&nbsp;
        <Menu 
          style={{maxHeight: '150px'}}
          styles={{
            body: {
              overflowY: 'scroll'
            }
          }}
          withinPortal={true} 
          zIndex={99999} 
          opened={openIsEarlyPaymentMenu === mapping.id} 
          onOpen={()=> hideDeleteButton === false && this.setState({openIsEarlyPaymentMenu: openIsEarlyPaymentMenu === false ? mapping.id : false, stopToggle: openIsEarlyPaymentMenu === false ? true : false})} 
          onClose={()=> this.setState({openIsEarlyPaymentMenu: false, stopToggle: false})}
          control={
          <div>
            <TruncatedTextHandler 
            truncate={false}
            removePointerCursor={hideDeleteButton}
            maxWidth={130} 
            className={'transaction-mapping-capsules-receipts'} 
            text={mapping.isEarlyPaymentEnabled ? 'zahlt vorfristig' : 'zahlt regulär'}/> 
          </div>}>
          <Menu.Item className='tooltips transaction-mapping-rental-payment-early-payment-true' style={{background: mapping.isEarlyPaymentEnabled && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'isEarlyPaymentEnabled', true)}>{'zahlt vorfristig'}</Menu.Item>
          <Menu.Item className='tooltips transaction-mapping-rental-payment-early-payment-false' style={{background: !mapping.isEarlyPaymentEnabled && '#e6e6ff', fontSize: '12px'}} styles={{itemLabel: {lineHeight: '15px'}}} onClick={()=> this.handleMappingChange(mapping.id, 'isEarlyPaymentEnabled', false)}>{'zahlt regulär'}</Menu.Item>
        </Menu>&nbsp;)
        
      </span>
      {hideDeleteButton === false &&
      (mappingLoader === mapping.id ?
      <div>
        <Loader size={'xs'}/>
      </div>
      :
      <div>
        <MantineTooltip zIndex={99999} label='Regel löschen'><button onClick={()=> this.deleteTransactionMapping(mapping.id)} type="button" class="close delete-cost-position" aria-label="Close"><span aria-hidden="true">×</span></button></MantineTooltip>
      </div>)}
    </Group>
    )
  }

  renderBody=()=>{
    const { mappings, isTablet, isMobile, search } = this.state;
    console.log('search: ', search);
    return (
      <TableBody>
        {mappings.filter((mapping)=> search ? mapping.counterpartName?.toString().toLowerCase().includes(search.toString().toLowerCase()) ? mapping : mapping.counterpartIban?.toString().toLowerCase().includes(search.toString().toLowerCase()) : mapping)
        //.filter((mapping)=> search ? mapping.counterpartIban?.toString().toLowerCase().includes(search.toString().toLowerCase()) : mapping)
        .map((mapping)=>
        <TableRow style={{background: '#FFFFFF'}}>
          <TableCell>{mapping.counterpartIban}</TableCell>
          <TableCell>
            <TruncatedTextHandler maxWidth={(isTablet || isMobile) ? 100 : 200} text={mapping.counterpartName ? mapping.counterpartName : '-'}/></TableCell>
          <TableCell>{mapping.transactionType === 'Expenditure' ? 'Ausgabe' : 'Mieteinnahme'}</TableCell>
          <TableCell style={{overflow: 'visible'}}>
            {mapping.transactionType === 'Expenditure' ?
            this.renderExpenditureMapping(mapping)
            :
            this.renderRentMapping(mapping)}
          </TableCell>
        </TableRow>)}
      </TableBody>
    )
  }

  renderMappingsTable=()=>{
    return (
      <TableContainer style={{maxHeight: '400px'}}>
        <Table className='rental-income-list-table' stickyHeader>
          {this.renderHeader()}
          {this.renderBody()}
        </Table>
      </TableContainer>
    )
  }
  
  render() {
    const { open, toggle, className } = this.props;
    const { mappings, stopToggle, isTablet, isMobile, search, loader } = this.state;
    
    return (
      <CustomModal
        heading={'Alle Regeln'}
        headingClassName={'update-received-rent-modal-heading'}
        open={open}
        toggle={() => {
          console.log('toggle');
          if(!stopToggle){
            toggle();
          }
        }}
        className={`${className}`}
        style={{minWidth: isMobile ? 'auto' : isTablet ? '800px' : '1200px'}}
        styleBody={{background: '#F7F7FA'}}
      >
        <Stack>
          <Group>
            <div className='filters' style={{width: '100%'}}>
              <div className='section-1' style={{width: '100%'}}>
                <SearchContainer
                value={search}
                id={'search-input-for-banks'}
                onChange={(e)=> this.setState({search: e.target.value})}
                disabled={mappings.length === 0}
                placeholder={'Suche IBAN oder Sender / Empfänger'}
                style={{width: '100%'}}
                />
              </div>
            </div>
          </Group>
          {loader ? 
          <Group position='center' align={'center'} style={{height: '450px'}}>
            <Loader/>
          </Group>
          :
          mappings.length > 0 ?
          <Group>
            {this.renderMappingsTable()}
          </Group>
          :
          <Group position='center' align={'center'} style={{height: '450px'}}>
            <EmptyStateContainer
            heading={'Noch keine Regeln erstellt.'}
            subheading={'Regeln, die Sie zur automatischen Verarbeitung von Zahlungen anlegen, werden Ihnen später hier angezeigt.'}
            icon={Bank}
            iconWidth={'20px'}
            showBtn={false}
            />
          </Group>}
          <Group position='right'>
              <div>
                  <ColorButton
                  text={'Schließen'}
                  className='gray-btn'
                  onClick={()=> toggle()}
                  />
              </div>
          </Group>
        </Stack>
      </CustomModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    rentalUnits: state.tenantDataReducer.rentalUnits,
    accountData: state.generalReducer.accountData
  };
};

export default connect(mapStateToProps)(AllTransactionMappingsModal);;
