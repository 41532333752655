import { useRef, useState, useEffect } from 'react';
import { Text, Tooltip } from '@mantine/core';

function TruncatedTextHandler({ text, maxWidth=200, className, marginTop='0px', onClick, alternateTooltipText=null, truncate=true, alwaysEnableTooltip=false, removePointerCursor=false }) {
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      setIsTruncated(textRef.current.scrollWidth > textRef.current.clientWidth);
    }
  }, [text]);

  return (
    <Tooltip zIndex={99999} label={alternateTooltipText ? alternateTooltipText : text} style={{marginTop: marginTop, cursor: removePointerCursor ? 'default' : 'pointer'}} disabled={alwaysEnableTooltip ? false : !isTruncated}>
      <Text
        ref={textRef}
        style={{
          maxWidth: truncate ? `${maxWidth}px` : 'auto',
          overflow: 'clip',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        className={className}
        onClick={onClick}
      >
        {text}
      </Text>
    </Tooltip>
  );
}

export default TruncatedTextHandler;
