import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import CustomModal from '.';
import { showNotification } from '@mantine/notifications';
import ColorButton from '../Buttons/ColorButton';
import CopyIcon from '../../../SVGs/copyIcon';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
import { Checkbox, Group, Stack, TextInput } from '@mantine/core';
import EmptyStateContainer from '../EmptyStateContainer/EmptyStateContainer';
import TenantIconBlue from '../../../../../assets/images/module/general/TenantIconBlue.svg';
import XlsxIcon from '../../../../../assets/images/module/general/XlsxIcon.svg';
import XlsxIconGrey from '../../../../../assets/images/module/general/XlsxIconGrey.svg';
import { NumericFormat } from 'react-number-format';
import DropFile from '../DropFile/DropFile';
import * as XLSX from "xlsx";
import { connect } from 'react-redux';


const MOBILE_SIZE = 992;
const TABLET_SIZE = 1200;

const keyMapping = {
  Einheit: "rentalUnitName",
  Mietertyp: "type",
  "Mieter  Vor- und Nachname ": "name",
  "Telefonnummer (opt.)": "phone",
  "E-Mail Adresse (opt.)": "email",
  "Einzugsdatum (TT.MM.JJJJ)": "in",
  "Auszugsdatum (TT.MM.JJJJ) (opt.)": "out",
  Kaltmiete: "netRent",
  Vorauszahlungen: "prePayments",
  "Notizen (opt.)": "notes"
};

export class ImportTenantsFromExcelFileModal extends Component {
  state = {
    isMobile: window.innerWidth <= MOBILE_SIZE,
    isTablet: window.innerWidth <= TABLET_SIZE,
    excelFile: null,
    hoverExcelIcon: false,
    allTenantsList: [],
    openErrorLogForTenantUploadModal: false
  };
  
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    const {rentalUnits} = this.props;
    let tenants=[];
    
    console.log('componentDidMount: ', rentalUnits);
    rentalUnits.map((rentalUnit)=> rentalUnit.tenantsData.map((tenant)=> tenant.vacant === false && tenants.push({...tenant, rentalUnitName: rentalUnit.name, alreadyInSystem: true})));

    this.setState({tenants});
  }

  handleExcelFileUpload = (acceptedFile, rejectedFile) => {
    const file = acceptedFile[0];
    this.setState({excelFile: [file]});
    const reader = new FileReader();
    console.log('jsonData: ', file);
    reader.onload = (e) => {
      const binaryData = e.target.result;
      const workbook = XLSX.read(binaryData, { type: "binary" });

      // Read the first sheet
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      // Convert sheet to JSON
      let jsonData = XLSX.utils.sheet_to_json(sheet);
      jsonData= jsonData.map((jsDt)=> this.replaceObjectKeys(jsDt, keyMapping));

      console.log('jsonData: ', jsonData);
      if(jsonData.length > 0){
        let {tenants} = this.state;
        jsonData.map((tenant)=> tenants.push(tenant));
        this.setState({tenants}, this.checkForDatesConflict());
      }
    };

    reader.readAsBinaryString(file);
  }

  checkForDatesConflict=()=>{
    const {tenants} = this.state;
    let groupByRentalUnits = [];
    const rentalUnits = this.removeDuplicatesFromArray(tenants.map((tenant)=> tenant.rentalUnitName));
    rentalUnits.forEach((rentalUnitName)=> {
      let filterTenantsByRentalUnit = tenants.filter((tenant)=> tenant.rentalUnitName === rentalUnitName);
      let rentalUnit = {
        name: rentalUnitName,
        tenants: filterTenantsByRentalUnit
      }
      groupByRentalUnits.push(rentalUnit);
    });
    console.log('groupByRentalUnits: ', groupByRentalUnits);
    let overlapsDetected = []
    groupByRentalUnits.forEach((rentalUnit)=> {
      let detectedOverlaps = this.detectOverlappingTenantsOptimized(rentalUnit.tenants);
      detectedOverlaps.map((detectedOverlap)=> overlapsDetected.push({...detectedOverlap, dateOverlap: true}));
    })
    console.log('overlapsDetected: ', overlapsDetected);

    if(overlapsDetected.length > 0){
      this.setState({openErrorLogForTenantUploadModal: true, overlapsDetected});
    }else{
      this.createNewTenants(tenants.filter((tenant)=> tenant.alreadyInSystem === false));
    }
  }

  createNewTenants=(tenants)=>{
    console.log('createNewTenants: ', tenants);
  }

  detectOverlappingTenantsOptimized = (tenants) => {
    // Sort tenants by in
    tenants.sort((a, b) => new Date(a.in) - new Date(b.in));
  
    const overlaps = [];
  
    for (let i = 0; i < tenants.length; i++) {
      const tenantA = tenants[i];
      const moveInA = new Date(tenantA.in);
      const moveOutA = tenantA.out ? new Date(tenantA.out) : Infinity;
  
      for (let j = i + 1; j < tenants.length; j++) {
        const tenantB = tenants[j];
        const moveInB = new Date(tenantB.in);
  
        if (moveInB > moveOutA) break; // No overlap possible since list is sorted
  
        const moveOutB = tenantB.out ? new Date(tenantB.out) : Infinity;
  
        if (moveInA <= moveOutB && moveInB <= moveOutA) {
          overlaps.push([tenantA, tenantB]);
        }
      }
    }
  
    return overlaps;
  };

  removeDuplicatesFromArray = (array) => [...new Set(array)];

  removeDuplicatesFromArrayOfObjects = (array, key) => {
    const seen = new Set();
    return array.filter((item) => {
      const keyValue = item[key];
      if (seen.has(keyValue)) {
        return false;
      }
      seen.add(keyValue);
      return true;
    });
  };

  removeFile=()=>{
    this.setState({excelFile: null});
  }

  replaceObjectKeys = (obj, keyMapping) => {
    return Object.keys(obj).reduce((newObj, key) => {
      // Use the mapped key if it exists, otherwise use the original key
      const newKey = keyMapping[key] || key;
      newObj[newKey] = obj[key];
      return {...newObj, alreadyInSystem: false};
    }, {});
  };

  handleDownload = () => {
    // Create a link element
    const link = document.createElement("a");
    // Set the href to the file path
    link.href = '/Mieter hinzufügen.xlsx';
    // Set the download attribute
    link.download = 'Mieter hinzufügen.xlsx';
    // Append the link to the body
    document.body.appendChild(link);
    // Programmatically click the link
    link.click();
    // Remove the link from the document
    document.body.removeChild(link);
  };
  
  render() {
    const { open, toggle, className, update } = this.props;
    const {excelFile, hoverExcelIcon, tenants} = this.state;

    console.log('tenants: ', tenants);
    
    return (
      <CustomModal
        heading={'Sammel-Upload für mehrere Mieter'}
        headingClassName={'update-received-rent-modal-heading'}
        open={open}
        toggle={() => {
          toggle();
        }}
        className={`${className}`}
      >
        <Stack>
            <Group spacing={0}>
              <p style={{fontFamily: 'Inter, sans-serif', fontWeight: '400', marginBottom: 0, color: '#13135c'}}>Please download following <span style={{fontWeight: 'bold'}}>Mieter hinzufügen.xlsx</span> file and please fill in your data according to our set format. Then upload to the system to import all tenants at once.</p>
            </Group>
            <Group position='center' noWrap >
              <Group className='excel-tenants-file-container' onClick={()=> this.handleDownload()} spacing={10} noWrap onMouseEnter={()=> this.setState({hoverExcelIcon: true})} onMouseLeave={()=> this.setState({hoverExcelIcon: false})}>
                <img src={hoverExcelIcon ? XlsxIcon : XlsxIconGrey} style={{width: '23px'}}/>
                <span className='excel-tenants-file-name'>Mieter hinzufügen.xlsx</span>
              </Group>
            </Group>
            <Group spacing={0} style={{marginTop: 20}}>
              <p style={{fontFamily: 'Inter, sans-serif', fontWeight: '400', marginBottom: 0, color: '#13135c'}}>Upload back your <span style={{fontWeight: 'bold'}}>Mieter hinzufügen.xlsx</span> file here to import all tenants at once.</p>
            </Group>
            <Group style={{width: '100%'}} grow>
              <div>
                <DropFile
                downloadable
                accept={'.xlsx'}
                customProps={{ multiple: false }}
                handleDrop={this.handleExcelFileUpload}
                file={excelFile}
                removeFile={this.removeFile}
                multiSelect={false}
                maxSize={100000000}
                hideSecondText={true}
                />
              </div>
            </Group>
            <Group position='apart'>
                <div>
                    <ColorButton
                    text={'Abbrechen'}
                    className='gray-btn'
                    onClick={()=> toggle()}
                    />
                </div>
                <div>
                    <ColorButton
                    text={'Fortfahren'}
                    className='purple-btn'
                    onClick={()=> update()}
                    />
                </div>
            </Group>
        </Stack>
      </CustomModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    propertySelected: state.generalReducer.property,
    rentalUnits: state.tenantDataReducer.rentalUnits,
  };
};

export default connect(mapStateToProps)(ImportTenantsFromExcelFileModal);
