import React, { Component } from 'react';
import {
  ColorButton,
  ValueText,
  RecordOptions,
  ShowModal,
  ReceiptDetailModal,
  DeleteModal,
  ImageModal,
} from '../../../../index';
import {
  Table, 
  TableBody, 
  TableCell,
  TableRow,
  Checkbox,
  TableSortLabel,
  TableContainer,
  TableHead
} from '@material-ui/core';
import TapIconDark from '../../../../../assets/images/module/general/newIconReceipt.svg';
import TapIconLight from '../../../../../assets/images/module/general/UtilityIcon.svg';
import AttachmentIcon from '../../../../../assets/images/module/general/AttachmentIcon.svg';
import RightArrowPartner from '../../../../../assets/images/module/general/RightArrowPartner.svg';
import RedTick from '../../../../../assets/images/module/general/RedTick.svg';
import RedCross from '../../../../../assets/images/module/general/RedCross.svg';
import GreenTick from '../../../../../assets/images/module/general/GreenTick.svg';
//import ExcelIcon from '../../../../components/SVGs/excel-file-icon.svg';
import Receipt from '../../../../../assets/images/module/general/ReceiptDummy.png';
import { connect } from 'react-redux';
import Autoload from '../../../../Autoload';
import moment from 'moment';
import { downloadExcel } from 'react-export-table-to-excel';
import UtilityBillingAssignedIcon from '../../../SVGs/utilityBillingAssigned';
//import DownloadIcon from '../../../../../assets/images/module/general/DownloadIcon.svg';
import DownloadIcon from '../../../SVGs/downloadIcon';
import NotificationIcon from '../../../SVGs/notificationIcon';
import { Group, Skeleton } from '@mantine/core';
import { hideNotification, showNotification } from '@mantine/notifications';
import accounting from 'accounting';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReceiptDuplicateProgressModal from '../CustomModal/ReceiptDuplicateProgressModal';
import {
  setOpenReceiptDuplicateProgressModal,
  setSelectedReceiptForDuplication
} from '../../../../../redux/action/General/generalActions';
//'../redux/action/General/generalActions'

var Setting = Autoload.Setting;
var lang = Setting[Setting.language_default].general;
var lang_receipt = Setting[Setting.language_default].receipt;
var langnewKeys = Setting[Setting.language_default].newKeys;

const tableCellStyle={
  fontSize: '14px',
  fontWeight: '400',
  fontFamily: 'Inter, sans-serif',
}

class ReceiptRecord extends Component {
  state = {
      currentRecord: {},
      showModal: false,
      deleteOpen: false,
      editReceiptOpen: false,
      ImageOpen: false,
      orderBy: '',
      order: 'asc',
      selectedReceipts: [],//,
      selectedReceiptForDuplication: null,
      openReceiptDuplicateProgressModal: false,
      newSelectedReceiptsForTransactionAssignment: []
      // myRef: React.createRef()
  };

  // scroll = (scrollOffset) => {
  //   this.state.myRef.current.scrollLeft += scrollOffset;
  // };

  componentDidUpdate(){
    if(this.props.selectedReceipts.length === 0 && this.props.resetSelectedReceipts){
      this.setState({selectedReceipts: []}, ()=> this.props.setResetSelectedReceipts(false));
    }

    if(this.props.transactionAssignment && this.props.selectedReceipts.length > 0 && this.state.selectedReceipts.length === 0){
      this.setState({selectedReceipts: this.props.selectedReceipts});
    }
  }

  toggleDeleteModal = (refresh=false) => {
    this.setState({ deleteOpen: !this.state.deleteOpen }, ()=> refresh && this.props.refreshData());
  };

  toggleEditReceiptModal = () => {
    this.setState({ editReceiptOpen: !this.state.editReceiptOpen });
  };

  closeEditReceipt = () => {
    this.setState({ editReceiptOpen: false });
  };
  openEditReceipt = (id) => {
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'attachment/' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handle_edit_receipt',
      id,
      false,
      '',
      {
        // 'Content-Type': 'text/xml',
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  _handle_edit_receipt = (response) => {
    console.log(response, 'handle edit receipt');
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        langnewKeys.text_error_not_found
        // + response.message
      );
    }
    if(response && response.result){//.includes('pdf')
      fetch(Autoload.Setting.rm_api_base_url + 'attachment/' + response.other +'/download',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          'Authorization': Autoload.Utils.getAuthToken()
        }
      })
            .then(response => {
                response.blob()
                .then(blob => {
                  console.log('blob: ', blob)
                  
                  this.setState({
                    currentRecord: { ...this.state.currentRecord, file_name: blob.size > 0 ? blob : '' }, 
                  }, ()=> this.setState({editReceiptOpen: true}));
                  })
                  var loader = document.getElementById('hello-housing-loader');
                  loader.style.display = 'none';
              })
    } else {
      this.setState({
        currentRecord: { ...this.state.currentRecord, file_name: response.result != null ? response.result : '' },
        editReceiptOpen: true,
      });
    }
  };

  openShow = (id) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';

    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'attachment/' + id,
      'GET',
      null,
      null,
      false,
      true,
      false,
      this,
      '_handle_get_show_file',
      id,
      false,
      '',
      {
        'Content-Type': 'application/json',
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };
  
  _handle_get_show_file = (response) => {
    if (response.status === false && response.message !== 'Not Found') {
      // Autoload.Redirection.redirection_error();
      Autoload.DataHandlerGeneral.GeneralErrorMessage(
        true,
        langnewKeys.text_error_not_found
        // + response.message
      );
    }

    if(response && response.result.includes('pdf')){
      fetch(Autoload.Setting.rm_api_base_url + 'attachment/' + response.other +'/download',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          'Authorization': Autoload.Utils.getAuthToken()
        }
      })
            .then(response => {
                response.blob()
                .then(blob => {
                  console.log('blob: ', blob)
                  
                  this.setState({
                    currentRecord: { ...this.state.currentRecord, file_name: blob.size > 0 ? blob : '' },
                    showModal: true,
                  });
                  })
                  var loader = document.getElementById('hello-housing-loader');
                  loader.style.display = 'none';
              })
    } else {
      this.setState({
        currentRecord: { ...this.state.currentRecord, file_name: response.result != null ? response.result : '' },
        showModal: true,
      });
      var loader = document.getElementById('hello-housing-loader');
      loader.style.display = 'none';
    }
  };

  closeShow = () => {
    // var loader = document.getElementById('hello-housing-loader');
    // loader.style.display = 'block';
    this.setState({ showModal: false });
    // var loaderClose = document.getElementById('hello-housing-loader');
    // loaderClose.style.display = 'none';
  };

  toggleImageModal = () => {
    this.setState({ ImageOpen: !this.state.ImageOpen });
  };

  handleEditClick = () => {
    this.closeShow();
    setTimeout(() => this.openEditReceipt(this.state.currentRecord?.id), 500); //this.toggleEditReceiptModal()
  };

  getRecord = (id, openShow=true, openEdit=false) => {
    let record = this.props.data.find((rcd) => rcd.id === id);
    this.setState({ currentRecord: record }, ()=> {
      openShow && this.openShow(id); 
      openEdit && this.openEditReceipt(id)});

    // setCurrentRecord(record)
  };
////sorting functions start
  descendingComparator = (a, b, orderBy)=> {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  getComparator= (order, orderBy)=> {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }
  
  stableSort=(array, comparator)=> {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  onRequestSort = (event, property) => {
    console.log('onRequestSort: ', property);
    const {order, orderBy} = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({order: isAsc ? 'desc' : 'asc', orderBy: property});
    this.props.setOrder(isAsc ? 'desc' : 'asc', property);
  };
////sorting functions end

  getTableLabels = () => {
    const {order, orderBy, selectedReceipts} = this.state;
    const {transactionAssignment} = this.props;
    const createSortHandler = (property) => (event) => {
      this.onRequestSort(event, property);
    };
    console.log('this.props.labels: ', this.props.labels);
    var tableLabelsArr = this.props.labels.filter((label)=> transactionAssignment ? (label.property !== 'attachment' && label.property !== 'utilityBillingAllocated') : label)
    .map((label, index) => {
      return (
        <TableCell key={index + 1} classes={{stickyHeader: 'bank-transaction-sticky-header', root: 'bank-transaction-table-cell-root'}}//className={`${this.props.labelClassName[index]} d-none d-md-table-cell`}
        style={{
          textAlign: (label.property === 'utilityBillingAllocated') ? 'left' : label.property === 'costs' ? 'right' : label.property === 'attachment' && 'center', 
          minWidth: label.name === '' && '70px', //|| label.property === 'costs' && '150px',
          whiteSpace: 'nowrap',
          //paddingLeft: label.property === 'utilityBillingAllocated' && '30px'
          //padding: `10px ${index !== 0 ? label.property === 'costs' ? '50px' : '15px' : '0px'} 0px ${index !== 0 ? label.property === 'costs' ? '10px' : label.property === 'receiptDate' ? '0px' : '15px' : '0px'}`
          padding: '10px 15px 0px 15px'
          }}
          align={label.property === 'costs' ? 'right' : 'left'}>
          {(label.property !== '' && label.property !== 'attachment') ?  
          <TableSortLabel
            active={orderBy === label.property}
            direction={orderBy === label.property ? order : 'asc'}
            onClick={createSortHandler(label.property)}
          >
            {label.name === '' ? '' : label.name}
          </TableSortLabel> : 
          label.name}
        </TableCell>
      );
    });
    return (
      <>
        <TableCell key={0} classes={{stickyHeader: 'bank-transaction-sticky-header', root: 'bank-transaction-table-cell-root'}}//</>className={`d-none d-md-table-cell`}
        >
          {transactionAssignment ?
          'Auswahl'
          :
          <Checkbox
            checked={selectedReceipts.length === this.props.data.length ? true : false}
            style={{color: selectedReceipts.length === this.props.data.length ? '#5555FF' : '#9A9AB5'}}
            inputProps={{ 'aria-labelledby': 0 }}
            onClick={()=> 
              this.setState({selectedReceipts: selectedReceipts.length === this.props.data.length ? [] : this.props.data.map((item)=> item.id)}, 
              ()=> {this.props.disableDownload(this.state.selectedReceipts.length === 0 ? true : false); this.props.onReceiptSelect(this.state.selectedReceipts);})}
            indeterminate={selectedReceipts.length !== 0 && selectedReceipts.length !== this.props.data.length && true}
          />}
        </TableCell>
        {tableLabelsArr}
        
      </>
    )
  };

  getSortedData=(data)=>{
    const {transactionAssignment, edit} = this.props;
    const {order, orderBy, selectedReceipts, newSelectedReceiptsForTransactionAssignment} = this.state;
    console.log('newSelectedReceiptsForTransactionAssignment: ', newSelectedReceiptsForTransactionAssignment);
    let sortedData= [];
    if(transactionAssignment && edit){
      sortedData = data.sort((a, b) => {
        if(transactionAssignment){
          const isAInB = newSelectedReceiptsForTransactionAssignment.includes(a.id) ? false : selectedReceipts.includes(a.id);
          const isBInB = newSelectedReceiptsForTransactionAssignment.includes(b.id) ? false : selectedReceipts.includes(b.id);
        
          // Move the items that are in array B to the beginning
          if (isAInB && !isBInB) {
            return -1; // a should come first
          } else if (!isAInB && isBInB) {
            return 1; // b should come first
          } else {
            return 0; // keep their relative order
          }
        }else{
          return 0;
        }
      });
      console.log('getSortedData: ', transactionAssignment, selectedReceipts, sortedData);
    }else{
      sortedData = this.stableSort(data, this.getComparator(order, orderBy))
    }
    return sortedData;
  }

  generateDuplicateName(originalName, existingNames) {
    const baseName = originalName.trim();
    let newName = `${baseName} (Kopie)`;
    let counter = 1;
  
    // Check if the new name already exists
    while (existingNames.includes(newName)) {
      newName = `${baseName} (Kopie) ${counter}`;
      counter++;
    }
  
    return newName;
  }

  getTableData = () => {
    const { data, showOption, editOption, isMobile, isTablet, transactionAssignment, setOpenReceiptDuplicateProgressModal, setSelectedReceiptForDuplication } = this.props;
    const {order, orderBy, selectedReceipts, newSelectedReceiptsForTransactionAssignment} = this.state;
    console.log(selectedReceipts, editOption, 'data in receipt record');
    console.log('getTableData: ', data);
    return this.getSortedData(data)
    .map((row, rowIndex) => {
      const isItemSelected = selectedReceipts && selectedReceipts.length !== 0 ? (selectedReceipts.find((selectedReceipt)=> selectedReceipt === row.id) !== undefined ? true : false) : false;
      const isItemSelectedForTransactionAssignment = newSelectedReceiptsForTransactionAssignment && newSelectedReceiptsForTransactionAssignment.length !== 0 ? (newSelectedReceiptsForTransactionAssignment.find((selectedReceipt)=> selectedReceipt === row.id) !== undefined ? true : false) : false;
      return (isMobile ?
        <TableRow key={rowIndex} //className="table-data" 
        style={{background: 'white', height: '70px'}}
        >
          <TableCell style={{height: 'auto !important', maxWidth: '30px', padding: '0px 10px',}}>
            <Checkbox
              checked={isItemSelected}
              inputProps={{ 'aria-labelledby': rowIndex }}
              style={{color: isItemSelected ? '#5555FF' : '#9A9AB5', padding: '0px'}}
              onClick={()=> 
                this.setState({selectedReceipts: selectedReceipts && selectedReceipts.length !== 0 ? isItemSelected ? selectedReceipts.filter((receipt)=> receipt !== row.id) : [...selectedReceipts, row.id] : [row.id],
                newSelectedReceiptsForTransactionAssignment: newSelectedReceiptsForTransactionAssignment && newSelectedReceiptsForTransactionAssignment.length !== 0 ? isItemSelectedForTransactionAssignment ? newSelectedReceiptsForTransactionAssignment.filter((receipt)=> receipt !== row.id) : [...newSelectedReceiptsForTransactionAssignment, row.id] : [row.id]
              }, 
                ()=> {this.props.disableDownload(this.state.selectedReceipts.length === 0 ? true : false); this.props.onReceiptSelect(this.state.selectedReceipts)})}
            />
          </TableCell>
          <TableCell>
            <img src={AttachmentIcon} />
          </TableCell>
          <TableCell style={{ cursor: 'pointer', height: 'auto !important', width: 'auto', textAlign: 'left'}} 
          value={row.name} onClick={() => {
            this.getRecord(row.id, !row.isValidated ? false : true, !row.isValidated && true); 
          }}>
            <ValueText
              text={row.name}
              leftImage={{ img: row.utilityBillingRelevant === true ? TapIconDark : TapIconLight }}
              textStyle={{
                whiteSpace: 'nowrap', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis', 
                fontSize: '14px',
                fontWeight: '500',
                fontFamily: 'Inter, sans-serif',
                textAlign: 'left',
                marginRight: '8px',
                width: row.name.length >= 20 && '130px'
              }}
              notificationDot={!row.isValidated}
            />
          </TableCell>
          <TableCell className="receipt-german-three-dots" style={{height: 'auto !important', width: 'auto', padding: '0px 10px 0px 0px', textAlign: 'center'}} onClick={() => this.getRecord(row.id, false)}>
            {editOption && (
              <RecordOptions
                toggleDeleteModal={() => this.toggleDeleteModal()}
                toggleEditModal={() => this.openEditReceipt(row.id)}
                getRecord={()=> this.getRecord(row.id, false)}
                listOfItems={[
                  {
                    text: 'Duplizieren',
                    leftImage: 
                    <FileCopyIcon 
                    style={{
                      color: '#5655FE',
                      fontSize: '15px'
                      }}/>,
                    openFunction: (()=> {
                      let selectedReceiptForDuplication = row;
                      selectedReceiptForDuplication.name= this.generateDuplicateName(selectedReceiptForDuplication?.name, data.map((receipt)=> receipt.name));
                      setOpenReceiptDuplicateProgressModal(true); 
                      setSelectedReceiptForDuplication(selectedReceiptForDuplication);
                      //this.setState({selectedReceiptForDuplication, openReceiptDuplicateProgressModal: true})
                    })
                  }
                ]}
                style={{marginRight: '0px'}}
              />
            )}
          </TableCell>
        </TableRow>
        :
        <TableRow key={rowIndex} //className="table-data" 
        style={{background: 'white', height: '70px', backgroundColor: (transactionAssignment && isItemSelected) ? '#E3E3FC' : 'white'}}
        >
          <TableCell padding="checkbox" //style={{height: 'auto !important'}}
          width={'5%'}
          >
            <Checkbox
              checked={isItemSelected}
              inputProps={{ 'aria-labelledby': row.name }}
              style={{color: isItemSelected ? '#5555FF' : '#9A9AB5'}}
              onClick={()=> 
                this.setState({selectedReceipts: selectedReceipts && selectedReceipts.length !== 0 ? isItemSelected ? selectedReceipts.filter((receipt)=> receipt !== row.id) : [...selectedReceipts, row.id] : [row.id],
                newSelectedReceiptsForTransactionAssignment: newSelectedReceiptsForTransactionAssignment && newSelectedReceiptsForTransactionAssignment.length !== 0 ? isItemSelectedForTransactionAssignment ? newSelectedReceiptsForTransactionAssignment.filter((receipt)=> receipt !== row.id) : [...newSelectedReceiptsForTransactionAssignment, row.id] : [row.id]}, 
                ()=> {this.props.disableDownload(this.state.selectedReceipts.length === 0 ? true : false); this.props.onReceiptSelect(this.state.selectedReceipts)})}
            />
          </TableCell>
          <TableCell style={{ cursor: 'pointer',  padding: '10px 15px'}}//maxWidth: '90px', height: 'auto !important'}} 
          //className={`receipt`} 
          //width={'20%'}
          value={row.name} 
          onClick={() => {
            this.getRecord(row.id, !row.isValidated ? false : true, !row.isValidated && true); 
          }}>
            {/* <span onClick={() => this.openShow(row.id)} 
            style={{
              
              //width: '230px'
            }}
            > */}
              <ValueText
                text={row.name}
                leftImage={{ img: row.utilityBillingRelevant === true ? TapIconDark : TapIconLight}}
                textStyle={{
                  whiteSpace: 'nowrap', 
                  overflow: 'hidden', 
                  textOverflow: 'ellipsis', 
                  fontSize: '14px',
                  fontWeight: '500',
                  fontFamily: 'Inter, sans-serif',
                  marginRight: '4px',
                  marginLeft: '3px',
                  marginTop: '1px',
                  width: isTablet ? (row.name.length >= 20 ? '120px' : 'auto') : (row.name.length >= 20 ? '300px' : 'auto'),
                }}
                notificationDot={!row.isValidated}
              />
              {!row.isValidated && window.Intercom("update", {
                api_base: "https://api-iam.intercom.io",
                app_id: "vvrs5bx3",
                'Receipt Created Automated': !row.isValidated && true
              })}
            {/* </span> */}
          </TableCell>
          {transactionAssignment === false &&
          <TableCell 
          style={{...tableCellStyle, ...{padding: '10px 15px', textAlign: 'center'}}} 
          onClick={() => {
            this.getRecord(row.id, !row.isValidated ? false : true, !row.isValidated && true);
          }}
          >
            {row.fileName ? <img src={AttachmentIcon} style={{cursor: 'pointer'}}/> : '-'}
          </TableCell>}
          <TableCell //className={`amount d-none d-md-table-cell`} 
          //width={'2%'}
          style={{...tableCellStyle, ...{textAlign: 'right', height: 'auto', padding: '10px 15px'}}}
          >
            {/* {row.costs} */}
            {/* <div style={{marginTop: '1px',}}>
              {Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(row.costs + '', false)}
            </div> */}
            <span style={{color: row.costs < 0 ? '#E8193C' : '#059266', whiteSpace: 'nowrap'}}>{accounting.formatMoney(row.costs, { symbol: "",  format: "%v %s", precision: 2, thousand: '.', decimal: ',' })}</span>
            {/* {Autoload.MainHelper.ConverterCurrencyDe(row.costs + '', false)} */}
            {/* Conversion in EURO */}
          </TableCell>
          <TableCell //className={`cost-type  d-none d-md-table-cell`} 
          //width={'10%'}
          style={{...tableCellStyle, ...{padding: '10px 15px'}}}
          //style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '0px', paddingBottom: '0px', height: 'auto !important' }}
          >
            <p //className="ct-receipt" 
            style={{ width: 'auto', marginBottom: '0px',
            lineHeight: '24px',
            whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{row.utilityBillingRelevant && row.cost_type.length > 0 ? row.cost_type.join(', ') : '-'}</p>
          </TableCell>
          {<TableCell //className={`cost-type  d-none d-md-table-cell`} 
          //width={'10%'}
          style={{...tableCellStyle, ...{padding: '10px 15px'}}}
          //style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '0px', paddingBottom: '0px', height: 'auto !important' }}
          >
            <p //className="ct-receipt" 
            style={{ width: 'auto', marginBottom: '0px',
            lineHeight: '24px',
            whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{row.taxCostType.length > 0 ? row.taxCostType : '-'}</p>
          </TableCell>}
          <TableCell //className={`date  d-none d-md-table-cell`} 
          //width={'10%'}
          style={{...tableCellStyle, ...{height: 'auto', padding: '10px 15px'}}}>
            {
              // editOption == true ? moment(row.date).format('DD.MM.YYYY') :
              moment(row.receiptDate).format('DD.MM.YYYY')
            }
          </TableCell>
          <TableCell //className={`time-frame  d-none d-md-table-cell`} 
          //width={'12%'}
          style={{...tableCellStyle, ...{whiteSpace: 'nowrap', height: 'auto', padding: '10px 15px'}}}>
            {row.utilityBillingRelevant ? row.time_frame : '-'}
          </TableCell>
          {transactionAssignment === false &&
          <TableCell //className="empty" 
          //width={'10%'}
          style={{...tableCellStyle, ...{padding: '10px 15px', height: 'auto !important'}}}>
            {/* {showOption && (
              <ColorButton
                onClick={() => this.openShow(row.id)}
                className="show-btn  d-none d-md-table-cell"
                text={lang.text_show}
                rightImage={{ img: EyeIcon, style: { width: '15px', height: '10px' } }}
              />
            )} */}
            <div style={{display: 'flex', justifyContent: 'flex-start'}}>
              <div style={{width: 'auto', whiteSpace: 'nowrap'}}
              >
                {row.utilityBillingRelevant ? row.utilityBillingAllocated === 'COMPLETELY' ? 
                <div> {UtilityBillingAssignedIcon('#5555FF')}<span style={{marginLeft: '4%', }}>{'vollständig'}</span></div> : row.utilityBillingAllocated === 'PARTIALLY' ? 
                <div> {UtilityBillingAssignedIcon('#8b88f4')}<span style={{marginLeft: '4%', }}>{'teilweise'}</span></div> : 
                <div>{UtilityBillingAssignedIcon('#9A9AB5')}<span style={{marginLeft: '4%', }}>{'nicht abgerechnet'}</span></div> : 
                '-'}
              </div>
            </div>
            {/* {editOption && (
              <RecordOptions
                toggleDeleteModal={() => this.toggleDeleteModal()}
                toggleEditModal={() => this.openEditReceipt(row.id)}
              />
            )} */}
          </TableCell>}
          <TableCell //width={'5%'} 
          className="receipt-german-three-dots" style={{height: 'auto', padding: '10px 15px', textAlign: 'right'}} onClick={() => this.getRecord(row.id, false)}>
            {editOption && (
              <RecordOptions
                style={{marginRight: '0px'}}
                toggleDeleteModal={() => this.toggleDeleteModal()}
                toggleEditModal={() => this.openEditReceipt(row.id)}
                getRecord={()=> this.getRecord(row.id, false)}
                listOfItems={[
                  {
                    text: 'Duplizieren',
                    leftImage: 
                    <FileCopyIcon 
                    style={{
                      color: '#5655FE',
                      fontSize: '15px'
                      }}/>,
                    openFunction: (()=> {
                      let selectedReceiptForDuplication = row;
                      selectedReceiptForDuplication.name= this.generateDuplicateName(selectedReceiptForDuplication?.name, data.map((receipt)=> receipt.name));
                      setOpenReceiptDuplicateProgressModal(true); 
                      setSelectedReceiptForDuplication(selectedReceiptForDuplication);
                      //this.setState({selectedReceiptForDuplication, openReceiptDuplicateProgressModal: true});
                    })
                  }
                ]}
              />
            )}
          </TableCell>
        </TableRow>
      );
    });
  };

  downloadExcelTable = () => {
    const {selectedReceipts} = this.state;
    var header = this.props.labels.map((label)=> label.name);
    var body = this.props.data.map((row)=> {
      if(selectedReceipts.find((receiptId)=> receiptId === row.id)){
        return {
          'Beleg': row.name,
          'Betrag (EUR)': Autoload.MainHelper.ConverterCurrencyDe_DisplayOnly(row.costs + '', false),
          'Kostenart': row.cost_type.length > 0 ? row.cost_type[0] : '-',
          'Datum': moment(row.receiptDate).format('DD.MM.YYYY'),
          'Zeitraum': row.utilityBillingRelevant ? row.time_frame : '-',
          'Zugewiesen': row.utilityBillingAllocated
        }
      }
    });

    downloadExcel({
      fileName: this.props.propertySelected.name,
      sheet: "sheet 1",
      tablePayload: {
        header,
        body: body
      }
    });
  }

  deleteReceipt = (id) => {
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'block';
    Autoload.HttpRequestAxios.HttpRequest(
      Autoload.Setting.rm_api_base_url + 'receipt/' + id,
      'DELETE',
      null,
      null,
      false,
      true,
      false,
      this,
      'handleDeleteReceipt',
      null,
      false,
      '',
      {
        Authorization: Autoload.Utils.getAuthToken(),
      }
    );
  };

  handleDeleteReceipt(response) {
    if (response.status === false && response.message !== 'Not Found') {
      showNotification({
        message: 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut oder wenden Sie sich an den Support.',
        icon: <img src={RedCross} alt='red-tick'/>,
        id: 'deleteReceiptNotFound'
      });
    } else if(response.status === true && response.statuscode === 200) {
      showNotification({
        message: 'Der Beleg wurde gelöscht.',
        icon: <img src={RedTick} alt='red-tick'/>,
        id: 'deleteReceipt'
      });
    }
    
    var loader = document.getElementById('hello-housing-loader');
    loader.style.display = 'none';
    this.toggleDeleteModal(true);
  }

  render() {
    const { currentRecord, showModal, deleteOpen, editReceiptOpen, ImageOpen, myRef, openReceiptDuplicateProgressModal, selectedReceiptForDuplication } = this.state;
    const { data, className, handleDelete, parent, isMobile, firstRowLoader, transactionAssignment } = this.props;
    console.log('currentRecord; ', currentRecord);
    return (
      data.length > 0 && (
        <Group style={{maxHeight: transactionAssignment && '450px', overflowY: transactionAssignment && 'scroll'}}>
        <TableContainer ref={myRef} className='receipts-table' style={{maxHeight: !transactionAssignment && 'calc(100vh - 190px)', overflowX: 'scroll'}}>
        <Table  className={`all-receipts-table borderless ${className} mb-0`} //</TableContainer>className={`table borderless ${className} mb-0`} 
        stickyHeader>
          {!isMobile && 
          <TableHead>
            <TableRow className="table-heading">{this.getTableLabels()}</TableRow>
          </TableHead>}
          <TableBody>
            {firstRowLoader && 
            <TableRow style={{height: '70px'}}>
              <TableCell colSpan={10} style={{padding: 0}}>
                <Skeleton style={{height: '70px'}}/>
              </TableCell>
            </TableRow>}
            {this.getTableData()}
          </TableBody>

          {showModal &&
          <ShowModal
            onEditClick={this.handleEditClick}
            onExpansionClick={this.toggleImageModal}
            record={currentRecord}
            className="show-receipt-modal"
            open={showModal}
            history={parent.props.history}
            // toggle={this.toggleShowModal}
            closeShow={this.closeShow}
          />}
          <ImageModal
            img={currentRecord && currentRecord.file_name !== '' ? currentRecord.file_name : Receipt}
            className="expandImageModal"
            open={ImageOpen}
            toggle={this.toggleImageModal}
          />
          <DeleteModal
            record={currentRecord}
            className="delete-receipt-modal"
            open={deleteOpen}
            toggle={() => this.toggleDeleteModal()}
            handleDelete={(id) => this.deleteReceipt(id)}
            // showInfo={['name','cost']}
            // showInfo={[currentRecord.name, currentRecord.cost]}

            showtext={false}
            typeText={'Sind Sie sicher, dass Sie diesen Beleg löschen wollen?'}
            type={'Beleg'}
            modalId="receipt-delete-modal-footer"
          />
          {editReceiptOpen && (
            <ReceiptDetailModal
              history={this.props.history}
              parent={parent}
              record={currentRecord}
              className="add-edit-receipt-modal"
              edit={true}
              open={editReceiptOpen}
              toggle={() => this.closeEditReceipt()}
              onEditSave={() => this.props.onEditSave()}
              refreshData={()=> this.openEditReceipt(currentRecord.id)}
              showFirstRowLoader={()=> this.props.showFirstRowLoader()}
            />
          )}
          {openReceiptDuplicateProgressModal &&
          <ReceiptDuplicateProgressModal
          open={openReceiptDuplicateProgressModal}
          //data={data}
          selectedReceiptForDuplication={selectedReceiptForDuplication}
          toggle={(success)=> this.setState({openReceiptDuplicateProgressModal: false, selectedReceiptForDuplication: null}, ()=> {
            if(success){
              hideNotification('receiptDuplicationProcess');
              showNotification({
                message: 'Belegkopie wird erstellt.',
                icon: <img src={GreenTick} alt='green-tick'/>,
                id: 'receiptDuplicationProcess'
              });
            }
            this.props.refreshData();
          })}
          />}
        </Table>
        </TableContainer>
        {/* <div style={{position: 'absolute', right: 10, bottom: 0}} onClick={()=> this.scroll(1000)}>
          <img src={RightArrowPartner} style={{width: '20px', cursor: 'pointer'}}/>
        </div> */}
        </Group>
      )
    );
  }
}
// ReceiptRecord.defaultProps={
//   data:[]
// }
// export default ReceiptRecord;

const mapStateToProps = (state, ownProps) => {
  return {
    propertySelected: state.generalReducer.property,
    properties: state.generalReducer.allProperties,
    data: ownProps && ownProps.data.length > 0 ? ownProps.data : [],
  };
};
const actions = {
  //DuplicateReceipt
  setOpenReceiptDuplicateProgressModal,
  setSelectedReceiptForDuplication
};

export default connect(mapStateToProps, actions)(ReceiptRecord);
